import { useState, useEffect } from "react";
import { BoardService } from "../../../service/service";
import ReactPaginate from "react-paginate";
import Modal from 'react-modal';

const NoticeDetail = () => {

    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<any[]>([])
    const [showArticle, setShowArticle] = useState(false);

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    const getList = async () => {
        
        const offset = (currentPage-1) * 10;
        const result = await BoardService.noticeList(offset, 10);

        setTotalCount(result.data.data[1][0].totalCount);
        setRows(result.data.data[0])
    }

    const changePage = (event: any) => {
        setCurrentPage(event.selected);        
        
    };


    const select= async (articleIdx:number)=>{
        const result = await BoardService.noticeView(articleIdx);        
        setTitle(result.data.data.title)
        setContent(result.data.data.content)
        setShowArticle(true);

    }
    

    useEffect(() => {

        getList().then();

    }, [currentPage])


    return (
        <div className="mp-tab notice">
            <Modal
                style={{
                    overlay: {
                        //position: "fixed",
                        backgroundColor: "transparent",
                        outline: "none",
                        zIndex: 2000,
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%", height: "100%"
                    },
                    content: {
                        position: "absolute",
                        backgroundColor: "transparent",
                        overflow: "auto",
                        border: "none",
                        outline: "none",
                        width: "400px",
                        height: "800px",
                        alignSelf: "center",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        // zIndex: 99999,
                        // position: "absolute",
                        // // top: "234.5px",
                        // width: "62.5%", height: "780px"

                    },


                }}
                isOpen={showArticle}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                
                
                className="w-[50%] h-[58vh] mt-[10%] m-auto bg-bgColor text-lg rounded-[10px] drop-shadow-lg"
            >
                <div style={{ background: "white", boxShadow:"0 15px 20px 0 rgba(0,0,0,.25)", borderRadius:"2px", minWidth:"150px", margin:"5%", minHeight:"100px", maxWidth:"500px", padding:"24px 24px 0 24px" }}>
                    <div 
                        style={{color: "#000", fontWeight:"700", background:"#fafafa", borderBottom:"#eee 1px solid", borderRadius:"2px 2px 0 0" }}
                    className="flex justify-center h-[20%] items-center text-5xl bg-lightGreen rounded-t-[10px]">
                        {title}
                    </div>
                    <div style={{color:"#000", minHeight:"56px", padding: "16px 24px 16px 16px", fontFamily:"'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                        fontSize:"14px", lineHeight:"1.4285em"
                }}

                     className="p-[5%]"  dangerouslySetInnerHTML={ {__html: content}}  ></div>
                    <div
                        style={{background:"#fbfbfb", borderTop:"#eee 1px solid", borderRadius:"0 0 2px 2px",
                        padding:"4px", marginLeft:"-24px", marginRight:"-24px", minHeight:"43px", textAlign:"right"

                    
                    }}
                    >
                        <button style={{color:"#3593d2", margin:"4px", backgroundColor:"transparent", border:"0", fontSize:"14px", fontWeight:"700", textTransform:"uppercase"}} onClick={()=>{setShowArticle(false)}}>확인</button>
                    </div>
                </div>

            </Modal>
            <table className="bs-table" style={{ width: "95%" }}>
                <thead>
                    <tr>
                        <th>공지</th>
                        <th style={{ width: "70%" }}>제목</th>
                        <th>작성자</th>
                        <th>작성일</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((el, key) => {
                            return (
                                <tr>
                                    <td>[공지]</td>
                                    <td onClick={async ()=>{await select(el.articleIdx)}}>{el.title}</td>
                                    <td>{el.writer}</td>
                                    <td>{el.writeAt.substring(0, 10)}</td>
                                </tr>
                            )
                        })
                    }

                </tbody></table>
            <div className="pagination_box">
                <ReactPaginate
                    pageCount={Math.ceil(totalCount / 10)}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    breakLabel={""}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    onPageChange={changePage}
                    containerClassName={"_pagination"}
                    activeClassName={"active"}
                    previousClassName={"disabled"}
                    nextClassName={"disabled"}
                />

            </div>
        </div>
    )
}

export default NoticeDetail;