import { UserService } from "../../service/service";
import { useState } from "react";

const Join1 = ({ setModal, toast }: any) => {

    
    const [id, setId] = useState("");
    const idCheck =async () => {
        try{            
            const result = await UserService.agendId(id);                        
            sessionStorage.setItem('pCode', result.data.data.pCode);
            setModal("Join2")            
        }catch(error:any){
            if (error.response.data.code === "CHECK_ID") {
                toast("추천 아이디를 확인하세요");
            }else{
                toast("오류가 발생했습니다. 관리자에게 문의하세요");
            }
        }
    }



    return (
        <div id="SLB_wide" className="seven wide column" style={{ width: "100%" }} >
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "364px", width: "99%" }} className="ui-draggable ui-resizable"></div>
            <div className="SLB_caption ui-draggable-handle"> </div>
            <div className="SLB_close" onClick={() => { setModal(""); }}></div>
            <div className="SLB_center" >
                <div className="loginModal popupModal " role="dialog" id="loginModal">
                    <div className="bsm-cont" style={{ width: "99.9%", height: "500px" }}>
                        <div className="bsm-inner" style={{ padding: "0px" }}>
                            <div className="modal-body mypage-tabs">
                                <div className="mdl-head">
                                    <div className="mdl-title">
                                        <div className="active">
                                            <p className="en">회원가입</p>
                                            <p className="kr">추천인 입력</p>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="tab-mdl mp-tab active">
                                    <div className="form-container">
                                        <div id="formLogin">

                                            <div className="form-group">
                                                <div className="infos">
                                                    <input type="text" placeholder="추천인 아이디" name="userid" onChange={(e) => {
                                                        setId(e.target.value);
                                                    }} />
                                                    <ul className="parsley-errors-list" id="parsley-id-3409"></ul>
                                                </div>
                                            </div>

                                            <div className="modal-btn-foot">
                                                <div className="btn-cont">
                                                    <button className="red over-style login-btn" onClick={()=>{
                                                        idCheck();
                                                    }}>
                                                        <span className="os-cont"><i className="fa fa-lock-open"></i> 다음단계</span>
                                                    </button>
                                                    <button className="join-btn over-style" onClick={()=>{
                                                        setModal("");
                                                    }}>
                                                        <span className="os-cont">
                                                            <i className="fa fa-file-signature"></i> 취소
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}


export default Join1;