import Modal from "react-modal";

import "../../asset/css/css2/app.css"
import "../../asset/css/default.min.css"
//  import "../../asset/css/css2/style.css"

import Login from "./login";
import Live from "./live";
import Slot from "./slot";
import MyPage from "./mypage";
import Deposit from "./deposit";
import Join1 from "./join1";
import Join2 from "./join2";
import Withdraw from "./withdraw";
import Cash from "./cash";
import FadeLoader from "react-spinners/FadeLoader";
import { useState, useEffect } from "react";

const ModalBase = ({ setModal, modal, toast }: any) => {

    const [gameWindow, setGameWindow] = useState<Window | null>(null)


    const getDetail = () => {
        switch (modal) {
            case "Login":
                return <Login setModal={setModal} toast={toast}></Login>;
            case "Live":
                return <Live setModal={setModal} gameWindow={gameWindow} setGameWindow={setGameWindow} toast={toast}></Live>;
            case "Slot":
                return <Slot setModal={setModal} gameWindow={gameWindow} setGameWindow={setGameWindow} toast={toast}></Slot>;
            case "MyPage":
                return <MyPage setModal={setModal} cmd="myinfo" toast={toast}></MyPage>
            case "Qna":
                return <MyPage setModal={setModal} cmd="qna" toast={toast}></MyPage>
            case "Notice":
                return <MyPage setModal={setModal} cmd="notice" toast={toast}></MyPage>
            case "Memo":
                return <MyPage setModal={setModal} cmd="memo" toast={toast}></MyPage>
            case "Deposit":
                return <Deposit setModal={setModal} toast={toast}></Deposit>
            case "Join1":
                return <Join1 setModal={setModal} toast={toast}></Join1>
            case "Join2":
                return <Join2 setModal={setModal} toast={toast}></Join2>
            case "Withdraw":
                return <Withdraw setModal={setModal} toast={toast}></Withdraw>
            case "Cash":
                return <Cash setModal={setModal} toast={toast}></Cash>
            case "Loading":
                return <FadeLoader
                    color="white"
                    height={30}
                    width={10}
                    radius={2}
                    margin={2}
                />
        }
    }


    const getWidth = () => {

        if(windowSize[0] < 800){
            return "95%";
        }

        

        switch (modal) {
            case "Login":
            case "Join1":                
                return "30%";
            case "Join2":
                return "35%";
            default:
                return "62.5%";
        }
    }

    const isOpen = () => {
        if (modal == "") {
            return false;
        }

        return true;
    }


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  
    return (


        <div>


            <Modal
                id="SLB_content"
                onRequestClose={() => {
                    setModal(false);
                }}
                isOpen={isOpen()}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                // onAfterOpen={afterOpenModal}
                style={{
                    overlay: {
                        //position: "fixed",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        outline: "none",
                        zIndex: 1000,
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%", height: "1281px"
                    },
                    content: {
                        position: "absolute",
                        backgroundColor: "transparent",
                        overflow: "auto",
                        border: "none",
                        outline: "none",
                        width: getWidth(),
                        height: "850px",
                        alignSelf: "center",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        // zIndex: 99999,
                        // position: "absolute",
                        // // top: "234.5px",
                        // width: "62.5%", height: "780px"

                    },


                }}
                contentLabel="Example Modal"
                className="ui-resizable"

            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >

                    {getDetail()};
                </div>

            </Modal>


        </div>
    );
};


export default ModalBase