import { AccountService } from "../../../service/service"

import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const WithdrawHistory = () => {

    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<any[]>([])

    const getDepositHistory = async (offset: number) => {
        const id = sessionStorage.getItem("id");
        const result = await AccountService.withdrawList(id, 10, offset);

        setTotalCount(result.data.data.totalCount);
        setRows(result.data.data.rows)

    }

    const changePage = (event: any) => {

        const offset = (event.selected - 1) * 10;
        getDepositHistory(offset).then();
    };



    useEffect(() => {

        getDepositHistory(0).then();

    }, [])


    const getState = (state: string) => {
        switch (state) {
            case "A":
                return "승인";
            default:
                return state;
        }
    }


    return (
        <div className="mp-tab withdraw">
            {/* <div style="display: flex; justify-content: flex-start; align-items: center;"> */}
            <div>
                <div className="ui form">

                </div>
            </div>
            <table className="bs-table" style={{ width: "98%" }}>
                <thead>
                    <tr>
                        <th>신청일시</th>
                        <th>신청금액</th>
                        <th>현재상태</th>
                        <th>승인일시</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((el, key) => {
                            return (
                                <tr>
                                    <td>
                                        {el.requestAt}
                                    </td>
                                    <td>
                                        {el.amount.toLocaleString()}
                                    </td>
                                    <td>
                                        {getState(el.state)}
                                    </td>
                                    <td>
                                        {el.executeAt}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="pagination_box">
                <ReactPaginate
                    pageCount={Math.ceil(totalCount / 10)}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    breakLabel={""}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    onPageChange={changePage}
                    containerClassName={"_pagination"}
                    activeClassName={"active"}
                    previousClassName={"disabled"}
                    nextClassName={"disabled"}
                />

            </div>
        </div>
    )
}

export default WithdrawHistory;