import imgSlot7 from "../../asset/images/slot_7.png";
import imgSlot52 from "../../asset/images/slot_52.png";
import imgSlot4 from "../../asset/images/slot_4.png";
import imgSlot55 from "../../asset/images/slot_55.png";
import imgSlot3 from "../../asset/images/slot_3.png";
import imgSlot23 from "../../asset/images/slot_23.png";
import imgSlot9 from "../../asset/images/slot_9.png";
import imgSlot17 from "../../asset/images/slot_17.png";
import imgSlot24 from "../../asset/images/slot_24.png";
import { GameService } from "../../service/service";



const Slot = ({ setModal, gameWindow, setGameWindow, toast }: any) => {

    const sleep = (ms: number) => {
        return new Promise(resolve => {
            setTimeout(resolve, ms)
        })
    }

    
    // 게임 URL API
    const gameOpen = async (tpIdx: string, gameId: string) => {
        const id = sessionStorage.getItem("id");
        const data = { userId: id + "", tIdx: tpIdx, gameId: gameId };
        
        try {
            setModal("Loading")

            if (!(!gameWindow || gameWindow.closed)) {
                gameWindow.close();
                sleep(3000);

            }


            const result = await GameService.getGameUrl(data);            
            setGameWindow(window.open(result.data.data));
            setModal("")

        } catch (error:any) {
            setModal("")
            

            if(error.response.data.message == "Failed to transfer cash"){
                toast("머니 전송이 실패하였습니다. 열려있는 게임이 있으면 닫아주세요.");
            }else{                
                toast(error);
            }
            
            
            
        }
    };

    
    // 게임 URL API
    const gameDemo = async (gameId: string) => {        
        
        try {
            setModal("Loading")

            if (!(!gameWindow || gameWindow.closed)) {
                gameWindow.close();
                sleep(3000);

            }

            setGameWindow(window.open("https://demogamesfree-asia.ppgames.net/gs2c/openGame.do?lang=kr&cur=KRW&gameSymbol=" + gameId + "&jurisdiction=UK&lobbyURL=https://bm.t3all.com"));
            setModal("")

        } catch (error:any) {
            setModal("")            
            
            
        }
    };

    


    return (

        <div id="main-popup2">
            <div className="ngdialog ngdialog-theme-default ngdialog-gamespopup ng-scope">
                <div className="ngdialog-overlay"></div>
                <div className="ngdialog-content" role="document">
                    <div className="ngdialog-games-page" ng-init="activeCategory">
                        <div className="title ng-binding">슬롯게임</div>
                        <div className="game-button-container slot" id="slots">
                            <div className="game-buttons openGameBtn" title="Vampires vs Wolves" data-cid="7" data-onoff="on"
                                data-cname="Vampires vs Wolves">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs10vampwolf.png"} alt="Vampires vs Wolves" />
                                <span className="tit">Vampires vs Wolves</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs10vampwolf");
                                }}
                                >게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Starz Megaways" data-cid="52" data-onoff="on"
                                data-cname="Starz Megaways">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs117649starz.png"}  alt="Starz Megaways" />
                                <span className="tit">Starz Megaways</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs117649starz");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Fishin Reels" data-cid="4" data-onoff="on" data-cname="Fishin Reels">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs10goldfish.png"}  alt="Fishin Reels" />
                                <span className="tit">Fishin Reels</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs10goldfish");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Tic Tac Take" data-cid="55" data-onoff="on"
                                data-cname="Tic Tac Take">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs10tictac.png"}  alt="Tic Tac Take" />
                                <span className="tit">Tic Tac Take</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs10tictac");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Devil's 13" data-cid="3" data-onoff="on"
                                data-cname="Devil's 13">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs13g.png"} alt="Devil's 13" />
                                <span className="tit">Devil's 13</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs13g");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Money Money Money" data-cid="9" data-onoff="on" data-cname="BNG">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs1money.png"} alt="Money Money Money" />
                                <span className="tit">Money Money Money</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs1money");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Gates of Aztec" data-cid="23" data-onoff="on"
                                data-cname="Gates of Aztec">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs20aztecgates.png"} alt="Gates of Aztec" />
                                <span className="tit">Gates of Aztec</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs20aztecgates");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Candy Blitz" data-cid="17" data-onoff="on"
                                data-cname="Candy Blitz">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs20candyblitz.png"} alt="Candy Blitz" />
                                <span className="tit">Candy Blitz</span>
                                <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs20candyblitz");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Cleocatra" data-cid="24" data-onoff="on"
                                data-cname="Cleocatra">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs20cleocatra.png"} alt="Cleocatra" />
                                    <span className="tit">Cleocatra</span>
                                    <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameOpen("12", "vs20cleocatra");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Fortune" data-cid="24" data-onoff="on"
                                data-cname="Fortune">
                                <img className="game" src={"https://t3.prerelease-env.biz/game_pic/square/200/vs243fortune.png"} alt="Fortune" />
                                    <span className="tit">Fortune</span>
                                    <button className="btn btn-yellow btn-play" onClick={() => {
                                    gameDemo("vs243fortune");
                                }}>Demo</button>
                            </div>
                            {/* <div className="game-buttons openGameBtn" title="habanero" data-cid="25" data-onoff="on"
                                data-cname="habanero">
                                <img className="game" src="document_files/slot_25.png?v=1" alt="habanero" />
                                <span className="tit">하바네로</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="RELAX" data-cid="27" data-onoff="on"
                                data-cname="RELAX">
                                <img className="game" src="document_files/slot_27.png?v=1" alt="RELAX" />
                                <span className="tit">릴랙스게이밍</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="TPG" data-cid="34" data-onoff="on"
                                data-cname="TPG">
                                <img className="game" src="document_files/slot_34.png?v=1" alt="TPG" />
                                <span className="tit">트리플PG</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="GAMEART" data-cid="35" data-onoff="on"
                                data-cname="GAMEART">
                                <img className="game" src="document_files/slot_35.png?v=1" alt="GAMEART" />
                                <span className="tit">게임아트</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="playngo" data-cid="26" data-onoff="on"
                                data-cname="playngo">
                                <img className="game" src="document_files/slot_26.png?v=1" alt="playngo" />
                                <span className="tit">플레이앤고</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="PG" data-cid="37" data-onoff="on" data-cname="PG">
                                <img className="game" src="document_files/slot_37.png?v=1" alt="PG" />
                                <span className="tit">포켓게임즈</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="BlueprintGaming" data-cid="40" data-onoff="on"
                                data-cname="BlueprintGaming">
                                <img className="game" src="document_files/slot_40.png?v=1" alt="BlueprintGaming" />
                                <span className="tit">블루프린트</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Thunderkick" data-cid="41" data-onoff="on"
                                data-cname="Thunderkick">
                                <img className="game" src="document_files/slot_41.png?v=1" alt="Thunderkick" />
                                <span className="tit">썬더킥</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="NolimitCity" data-cid="42" data-onoff="on"
                                data-cname="NolimitCity">
                                <img className="game" src="document_files/slot_42.png?v=1" alt="NolimitCity" />
                                <span className="tit">노리밋시티</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="Mobilots" data-cid="43" data-onoff="on"
                                data-cname="Mobilots">
                                <img className="game" src="document_files/slot_43.png?v=1" alt="Mobilots" />
                                <span className="tit">모빌리엇츠</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="PlayPearls" data-cid="44" data-onoff="on"
                                data-cname="PlayPearls">
                                <img className="game" src="document_files/slot_44.png?v=1" alt="PlayPearls" />
                                <span className="tit">플레이펄즈</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="DragoonSoft" data-cid="45" data-onoff="on"
                                data-cname="DragoonSoft">
                                <img className="game" src="document_files/slot_45.png?v=1" alt="DragoonSoft" />
                                <span className="tit">드라군소프트</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="1X2GAMING" data-cid="49" data-onoff="on"
                                data-cname="1X2GAMING">
                                <img className="game" src="document_files/slot_49.png?v=1" alt="1X2GAMING" />
                                <span className="tit">1X2 Gaming</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div>
                            <div className="game-buttons openGameBtn" title="ELKSTUDIOS" data-cid="50" data-onoff="on"
                                data-cname="ELKSTUDIOS">
                                <img className="game" src="document_files/slot_50.png?v=1" alt="ELKSTUDIOS" />
                                <span className="tit">Elk Studios</span>
                                <button className="btn btn-yellow btn-play">게임입장</button>
                            </div> */}


                        </div>
                    </div>
                    <div className="ngdialog-close" onClick={()=>{setModal("")}}></div>
                </div>
            </div>
        </div>
    )
}


export default Slot;