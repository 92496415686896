import { useState, useEffect } from "react";
import { UserService, AccountService } from "../../service/service";


const Withdraw = ({ setModal, toast }: any) => {

    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [bankOwner, setBankOwner] = useState("");
    const [id, setId] = useState(sessionStorage.getItem("id"));
    const [bankName, setBankName] = useState("");
    const [bankAccount, setBankAccount] = useState("");

    const userInfo = async () => {
        let result = await UserService.getInfo();
        
        let bankOwner = result.data.data.bankOwner;
        if(bankOwner.length > 1){
            bankOwner = bankOwner.substring(0, 1) + "*" + bankOwner.substring(2)
        }

        let bankAccount = result.data.data.bankAccount;
        if(bankAccount.length > 5){
            bankAccount = "*****" + bankAccount.substring(bankAccount.length - 4)
        }
        setBankOwner(bankOwner)
        setBankName(result.data.data.bankName)
        setBankAccount(bankAccount)

    }


    const checkBalance = async () => {

        try {
            if (sessionStorage.getItem("id") != undefined) {
                let result = await UserService.checkBalance(sessionStorage.getItem("id"));
                setBalance(result.data.data.balance);
            }
        } catch (e: any) {
            if (e.response.status == "403") {
                sessionStorage.clear();
                window.location.reload();
            }
        }

    }

    useEffect(() => {
        checkBalance().then();
        userInfo().then();
    }, [])


    // 입금신청 API
    const withdraw = async () => {

        const userId = sessionStorage.getItem("id");
        try {

            if (balance < amount) {
                toast("보유 금액이 부족합니다.");
                return;
            }

            const result = await AccountService.requestWithdraw(userId, amount.toString());
            toast("출금신청 되었습니다.");
            setModal("");
        } catch (error:any) {
            if(error.response.data.message == "you need to play more games to withdraw"){
                toast("촐금하기 위한 베팅 금액이 부족합니다.")
            }else{
                toast("오류가 발생했습니다. 관리자에게 문의하세요.")
            }
        }
    };


    return (
        <div id="SLB_wide" className="ten wide column" style={{ width: "100%" }}>
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "0px", width: "99%" }} className="ui-draggable ui-resizable">
                <div className="SLB_caption ui-draggable-handle"> </div>
                <div className="SLB_close" onClick={() => { setModal(""); }}></div>
                <div className="SLB_center" >

                    <div role="dialog" id="chargeModal" className="chargeModal popupModal ">

                        <div className="bsm-cont" style={{ width: "99.9%", height: "750px" }}>
                            <div className="bsm-inner" style={{ padding: "0px" }}>
                                <div className="modal-body mypage-tabs">
                                    <div className="mdl-head">
                                        <div className="mdl-title">
                                            <div className="deposit active">
                                                <p className="en">WITHDRAW</p>
                                                <p className="kr">출금신청</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-mdl mp-tab active">

                                        <div className="form-container" style={{ width: "90%" }}>
                                            <div className="form-group">
                                                <div className="labels"><p>보유금액(C)</p></div>
                                                <div className="infos"><p className="_has_cash player-balance">{balance.toLocaleString()}</p></div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels"><p>은행명</p></div>
                                                <div className="infos">
                                                    <label><input type="text" name="bank_name" className="form-control " value={bankName} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group"><div className="labels"><p>출금자명</p></div>
                                                <div className="infos"><input type="text" name="bank_owner_replaced" className="form-control " value={bankOwner} />
                                                </div>
                                            </div>
                                            <div className="form-group"><div className="labels"><p>계좌번호</p></div>
                                                <div className="infos"><input type="text" className="form-control " value={bankAccount} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels"><p>출금금액</p></div>
                                                <div className="infos">
                                                    <input id="cash" name="cash" type="text" className="form-control " value={amount.toLocaleString()} />
                                                    <ul className="parsley-errors-list"></ul>
                                                    <div className="btn-grp" style={{ marginTop: "25px" }}>
                                                        <button type="button" onClick={() => { setAmount(amount + 10000) }} >1만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 50000) }}>5만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 100000) }}>10만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 500000) }}>50만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 1000000) }}>100만</button>
                                                        <button type="button" onClick={() => { setAmount(0) }} >다시입력</button>
                                                    </div>
                                                </div>                                                
                                            </div></div> <div className="modal-btn-foot">
                                            <div className="btn-cont"><button type="submit" className="over-style red">
                                                <span className="os-cont" onClick={withdraw}>출금하기</span></button>
                                                <button type="reset" className="over-style">
                                                    <span className="os-cont"  onClick={() => { setModal("") }}>취소</span></button>
                                            </div>
                                            {/* <div className="form-group">
                                                <div className="labels"><p>회원정보</p>
                                                </div>
                                                <div className="infos"><p>{nickName}({id})</p>
                                                </div></div>
                                            <div className="form-group">
                                                <div className="labels"><p>입금자명</p></div>
                                                <div className="infos">
                                                    <input type="text" name="req_name_replaced" placeholder="가입시 입력한 입금자명과 동일해야 합니다." className="form-control " onChange={(e) => { setOwner(e.target.value) }} />
                                                </div></div>
                                            <div className="form-group">
                                                <div className="labels"><p>입금금액</p></div>
                                                <div className="infos">
                                                    <input id="cash" name="cash" type="text" className="form-control " value={amount.toLocaleString()} />
                                                    <ul className="parsley-errors-list"></ul>
                                                    <div className="btn-grp" style={{ marginTop: "25px" }}>
                                                        <button type="button" onClick={() => { setAmount(amount + 10000) }} >1만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 50000) }}>5만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 100000) }}>10만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 500000) }}>50만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 1000000) }}>100만</button>
                                                        <button type="button" onClick={() => { setAmount(0) }} >다시입력</button>
                                                    </div></div></div>
                                        </div> <div className="modal-btn-foot"><div className="btn-cont">
                                            <button type="submit" className="over-style red">
                                                <span className="os-cont" onClick={depositApi} >입금하기</span></button>
                                            <button type="reset" className="over-style">
                                                <span className="os-cont" onClick={() => { setModal("") }}>취소</span></button></div></div>
                                        <div className="deposit-ask">
                                            <button type="button">
                                                <i className="ui icon question"></i> <span>입금계좌문의</span>
                                            </button> <p>* 입금시 꼭 계좌문의를 하세요!</p>
                                        </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui-resizable-handle ui-resizable-e" style={{ zIndex: 90 }}>
                </div>
                <div className="ui-resizable-handle ui-resizable-s" style={{ zIndex: 90 }}></div>
                <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se"
                    style={{ zIndex: 90 }}></div>
            </div>
        </div>
    )

}


export default Withdraw


{/* < div role = "dialog" id = "withdrawModal" className="withdrawModal popupModal" >
        <div className="bs-modal modal-dialog">
            <div className="bsm-cont"><div className="bsm-inner">
                <div className="modal-body"><div className="mdl-head">
                    <div className="mdl-title"><div className="active">
                        <p className="en">WITHDRAW</p>
                        <p className="kr">출금신청</p></div></div></div> \
                        <div className="tab-mdl withdraw active" style="padding-top: 0px;">
                            <form id="withdrawForm" name="withdrawForm">
                                <div className="form-container">
                                    <div className="form-group"><div className="labels"><p>보유금액(C)</p></div> <div className="infos"><p className="_has_cash player-balance">0</p></div></div> <div className="form-group"><div className="labels"><p>은행명</p></div> <div className="infos"><label><input type="text" name="bank_name" readonly="readonly" className="form-control "></label></div></div> <div className="form-group"><div className="labels"><p>출금자명</p></div> <div className="infos"><input type="text" readonly="readonly" name="bank_owner_replaced" className="form-control "> <input type="hidden" name="bank_owner" value="국민은"></div></div> <div className="form-group"><div className="labels"><p>계좌번호</p></div> <div className="infos"><input type="text" readonly="readonly" className="form-control "> <input type="hidden" name="bank_account" value="1346798520"></div></div> <div className="form-group"><div className="labels"><p>출금금액</p></div> <div className="infos"><input id="cash_out" name="cash" type="text" value="" readonly="readonly" className="form-control "> <div className="btn-grp" style="margin-top: 25px;"><button type="button" onclick="setMoneyField('cash_out',10000)">1만</button> <button type="button" onclick="setMoneyField('cash_out',50000)">5만</button> <button type="button" onclick="setMoneyField('cash_out',100000)">10만</button> <button type="button" onclick="setMoneyField('cash_out',500000)">50만</button> <button type="button" onclick="setMoneyField('cash_out',1000000)">100만</button> <button type="button" onclick="setMoneyField('cash_out',0)">다시입력</button></div></div></div></div> <div className="modal-btn-foot"><div className="btn-cont"><button type="submit" className="over-style red"><span className="os-cont">출금하기</span></button> <button type="reset" onclick="parent.SLB();" className="over-style"><span className="os-cont">취소</span></button>
                        </div></div></form></div></div></div></div></div></div> */}