import { ReactElement, useEffect, useState } from "react"

import imgOdometer from "../asset/images/odometer.png"
import $ from "jquery";

var p : NodeJS.Timer;

let count : number

const Odometer = (props:any) => {

    const {counterStart="0000.00"} = props;
    const {counterEnd="0000.00"} = props;
    const {delayTime=1e3} = props;
    const {increment=.01} = props;
    const {speed=500} = props;
    const {easing="swing"} = props;
    const {numbersImage="/images/jodometer-numbers.png"} = props;
    const {formatNumber=true} = props;
    const {heightNumber=14} = props;
    const {widthNumber=0} = props;
    const {offsetRight=0} = props;
    const {spaceNumbers=0} = props;
    const {widthDot=10} = props;
        

    const [content, setContent] = useState<ReactElement[]>([<span key={"1"}></span>]);
    const [digits, setDigits] = useState<string[]>([]);


    function round(m:any, tobe:number, asis:number) {
        if (tobe != asis) {            
            let a = $(".jodometer_integer_" +m)                    
            a.stop();
            if (tobe == 0) {
                a.animate({
                    top: 10 * heightNumber * -1  -heightNumber
                }, speed, easing).animate({
                    top:  -heightNumber
                }, 1, "linear")
            } else {
                if (tobe < asis) {
                    a.animate({
                        top: 10 * heightNumber * -1  -heightNumber
                    }, speed * ((10 - asis) / 10), "linear").animate({
                        top:  -heightNumber
                    }, 1, "linear").animate({
                        top: tobe * heightNumber * -1  -heightNumber
                    }, speed * asis / 10, easing)
                } else {
                    a.animate({
                        top: tobe * heightNumber * -1  -heightNumber
                    }, speed, easing)
                }
            }
        }

    }

    

    function start() {                
        if (count == 0) {
            clearInterval(p);            
            setCounter(count.toString())
        } else {            
            setCounter(count.toString());
            count = parseInt(count.toString()) + parseInt(increment)            
        }
    
        let end = parseFloat(counterEnd)
        if (end > 0 && count >= end) {            
            clearInterval(p);
            setCounter(end.toString())
        }
    }


    function setCounter(counter:string) {
        
        var oldDigit = 0;        
        let l = counter.length - 1;        
        
        
        for (let m = 0; m < counterStart.length; m++) {

            oldDigit = parseInt(digits[m]);
            digits[m] = counter.charAt(l);
            
            if (digits[m] == "") {
                digits[m] = "0"
            }
            setDigits(digits);
            round(m, parseInt(digits[m]), oldDigit)            
            l--
        }

    }

    


    useEffect(() => {        
        var _contnet : any = [];        
        var _digits : string[] = [];
        var e = -heightNumber;        
        var counter = String(counterStart).split(".");      


        var intCounter = counter[0];
        var l = intCounter.length - 1;
        var key = 0;
        var o = 0;        
        for (var m = 0; m < intCounter.length; m++) {
            var digit = intCounter.charAt(l);
            _digits.push(digit);            
            let right = parseFloat(m * widthNumber + o + offsetRight + m * spaceNumbers + spaceNumbers);

            
            if (formatNumber && m > 0 && m % 3 == 0) {                
                key++;
                _contnet.push(<div key={key} style={{ position: "absolute", width: `${widthDot}px`, height: `${heightNumber}px`, right: `${right}px` }} className="jodometer_dot"></div>);                
                o += parseFloat(widthDot + spaceNumbers);                
                
            }
            
            key++;
            right = parseFloat(m * widthNumber + o + offsetRight + m * spaceNumbers + spaceNumbers);
            _contnet.push(<img key={key} style={{ position: "absolute", right: `${right}px`, top: `${parseInt(digit) * heightNumber * -1 + e}px` }} className={"jodometer_integer_" + m} src={imgOdometer} alt="Integer 2" />)
            l--
        }

        setDigits(_digits);
        setContent(_contnet);

        count = counterStart;

        if (parseFloat(counterStart) != counterEnd) {
            
            p = setInterval(function() {
                start();
            }, delayTime)    
        
            
        }

        

    }, [])



    return (
        <div className="jackpot-odometer">            
            {content}
        </div>
    )

  

   
}

export default Odometer