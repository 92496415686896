import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GameService } from "../../../service/service";


const Betting = () => {

    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<any[]>([])

    const getDepositHistory = async (offset: number) => {
        const id = sessionStorage.getItem("id") + "";
        const result = await GameService.getBettingList(id, 10, offset);

        setTotalCount(result.data.data.totalCount);
        setRows(result.data.data.rows)

    }

    const changePage = (event: any) => {

        const offset = (event.selected - 1) * 10;
        getDepositHistory(offset).then();
    };



    useEffect(() => {

        getDepositHistory(0).then();

    }, [])

    return (
        <div className="mp-tab betting active">
            {/* <div style="display: flex; justify-content: flex-start; align-items: center;"> */}
            {/* <div>
                <div className="ui form">
                    <div className="fields">
                        <div className="field"><label>베팅일시</label>
                            <div className="inline field">
                                <input type="date" /> ~ <input type="date" /> <button className="ui black inverted button">검색</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <table className="bs-table">
                <thead>
                    <tr>
                        <th>게임사</th>
                        <th>게임</th>
                        <th>베팅일시</th>
                        <th>베팅</th> 
                        <th>결과</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((el, key) => {
                            return (
                                <tr>
                                    <td>
                                        {el.thirdParty}
                                    </td>
                                    <td>
                                        {el.gameName}
                                    </td>
                                    <td>
                                        {el.bettingAt}
                                    </td>
                                    <td>
                                        {el.bettingAmount.toLocaleString()}
                                    </td>
                                    <td>
                                        {el.winloseAmount.toLocaleString()}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="pagination_box">
                <ReactPaginate
                    pageCount={Math.ceil(totalCount / 10)}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    breakLabel={""}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    onPageChange={changePage}
                    containerClassName={"_pagination"}
                    activeClassName={"active"}
                    previousClassName={"disabled"}
                    nextClassName={"disabled"}
                />

            </div>
        </div>
    )
}

export default Betting;