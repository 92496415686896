import { UserService } from "../../service/service";
import { useState } from "react";



const Login = ({ setModal, toast }: any) => {

    const [pwd, setPwd] = useState("");
    const [id, setId] = useState("");



    // Login API
    const login = async () => {

        try {

            let loginData = {
                id: id, pwd: pwd
            }

            const result = await UserService.login(loginData);
            if (result.data.success) {
                sessionStorage.setItem("accessToken", result.data.data.accessToken);
                sessionStorage.setItem("checkSum", result.data.data.checkSum);
                sessionStorage.setItem("refreshToken", result.data.data.refreshToken);
                sessionStorage.setItem("id", id);
                window.location.reload();
                setModal("");
            }

        } catch (error: any) {
            if (error.response.data.code === "LOGIN_NOT_FOUND") {
                toast("아이디와 비밀번호를 확인해주세요.");
            } else if (error.response.data.code === "LOGIN_WRONG_PASSWORD") {
                toast("아이디와 비밀번호를 확인해주세요.");
            } else if (error.response.data.code === "ID_HAVEN'T_APPROVE_YET") {
                toast("관리자의 승인이 필요합니다. 잠시 기다려주세요.");
            } else {
                toast("오류가 발생했습니다. 관리자에게 문의하세요");
            }
            
        }
    };



    return (
        <div id="SLB_wide" className="seven wide column" style={{ width: "100%" }} >
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "364px", width: "99%" }} className="ui-draggable ui-resizable"></div>
            <div className="SLB_caption ui-draggable-handle"> </div>
            <div className="SLB_close" onClick={() => { setModal("") }}></div>
            <div className="SLB_center" >
                <div className="loginModal popupModal " role="dialog" id="loginModal">
                    <div className="bsm-cont" style={{ width: "99.9%", height: "500px" }}>
                        <div className="bsm-inner" style={{ padding: "0px" }}>
                            <div className="modal-body mypage-tabs">
                                <div className="mdl-head">
                                    <div className="mdl-title">
                                        <div className="active">
                                            <p className="en">LOGIN</p>
                                            <p className="kr">로그인</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-mdl mp-tab active">
                                    <div className="form-container">
                                        

                                        <div className="form-group">
                                            <div className="infos">
                                                <input type="text" placeholder="아이디" name="userid" onChange={(e) => {
                                                    setId(e.target.value);
                                                }} />
                                                <ul className="parsley-errors-list" id="parsley-id-3409"></ul>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="infos">
                                                <input type="password" placeholder="비밀번호" name="passwd" onChange={(e) => {
                                                    setPwd(e.target.value);                                                    
                                                }}
                                                />
                                                <ul className="parsley-errors-list" id="parsley-id-8611"></ul>
                                            </div>
                                        </div>


                                        <div className="modal-btn-foot">
                                            <div className="btn-cont">
                                                <button className="red over-style login-btn" onClick={() => {
                                                    login()
                                                }}>
                                                    <span className="os-cont"><i className="fa fa-lock-open"></i> 로그인</span>
                                                </button>
                                                <button className="join-btn over-style" onClick={() => {
                                                    setModal("Join1");
                                                }}>
                                                    <span className="os-cont">
                                                        <i className="fa fa-file-signature"></i> 회원가입
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}


export default Login;