import Request from "../@api/request";


// const baseURL = "https://back-dev.t3all.com"
const baseURL = "https://back.t3all.com"

const request = new Request(baseURL);

export class GameService {
    static getGameUrl = async (data: { userId: string; tIdx: string; gameId: string; }) => {
        return await request.post("user/game/open", data);
    }

    static getGameList = async (key: string) => {
        return await request.get(`user/game/list?tIdx=${key}`);
    }

    static getBettingList = async (userId: string, limit:number, offset:number) => {
        return await request.get(
            `user/${userId}/betting/list?limit=${limit}&offset=${offset}`
        );
    }    
}


export class UserService {
    static login = async (loginData: { id: any; pwd: string; }) => {
        const endPoint = "user/login";
        return await request.post(endPoint, loginData);
    }


    // static verifySMSCode = async (tel: string, code: string) => {
    //     return await request.post("user/sms/check", { tel, code });
    // }

    // static sendSMSCode = async (tel: string) => {
    //     return await request.post("user/sms", { tel });
    // }


    static getInfo = async () => {
        const endPoint = "user/myInfo";
        return await request.get(endPoint);
    }



    static updateUserInfo = async (id: any, pwd: string, newPw: string, name: string, tel: string) => {
        return await request.patch("user/" + `${id}` + "/update", {
            pwd,
            newPw,
            name,
            tel,
        });
    }

    static checkBalance = async (id: any) => {
        return await request.get(
            `user/${id}/totalbalance`
        );
    }

    static signup = async (id: string, pwd: string, nickName: string, tel: string, pCode: string, bankName: string, bankOwner: string, bankAccount: string) => {
        return await request.post("user/signup", {
            id,
            pwd,
            nickName,
            tel,
            recommenderId: "",
            bankName,
            bankOwner,
            bankAccount,
            pCode
        });
    }

    static idCheck = async (id: string) => {
        return await request.get("user/signup/" + `${id}`);
    }

    static agendId = async (id: string) => {
        return await request.get("user/agentId/" + `${id}`);
    }
}

export class BoardService {
    static eventList = async (limit: number, offset: number) => {
        return await request.get(
            `user/board/list?type=E&limit=${limit}&offset=${offset}`
        );
    }

    static noticeList = async (offset: number, limit: number) => {
        return await request.get(
            `user/board/list?board=NU&offset=${offset}&limit=${limit}`
        );
    }


    static noticeView = async (noticeIdx: any) => {
        return await request.get(
            "user/board/" + `${noticeIdx}` + "/select"
        );
    }

    static popupList = async () => {
        return await request.get(
            "user/popup/list"
        );
    }


}

export class AccountService {

    static recentWithdrawList = async (limit: number) => {
        return await request.get(
            `user/account/livelist?type=W&limit=${limit}`
        );
    }

    static deposit = async (userId: any, amount: string) => {
        return await request.post("user/request/deposit", {
            userId,
            amount,
        });
    }

    static requestWithdraw = async (userId: any, amount: string) => {
        return await request.post("user/request/withdraw", {
            userId,
            amount,
        });
    }

    static convertPoint = async (userId: any, amount: string) => {
        return await request.post("user/request/convertPoint", {
            userId,
            amount,
        });
    }


    static withdrawList = async (id: string | null, limit: number, offset: number) => {
        return await request.get(
            `user/request/list?userId=${id}&type=W&limit=${limit}&offset=${offset}`
        );
    }

    static depositList = async (id: string | null, limit: number, offset: number) => {
        return await request.get(
            `user/request/list?userId=${id}&type=C&limit=${limit}&offset=${offset}`
        );
    }




}


export class InquiryService {

    static inquiryList = async (offset: number, limit: number) => {

        const startDate = "2023-01-01";
        const endDate = "2043-01-01";

        return await request.get(
            `user/inquiry/list?startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`
        );
    }

    static inquiry = async (title: any, content: string) => {
        return await request.post("user/inquiry/post", {
            title,
            content,
            type: ""
        });
    }


    static inquiryView = async (inquiryIdx: number) => {
        return await request.get(
            `user/inquiry/${inquiryIdx}/select`
        );
    }

}

export class MemoService {

    static memoList = async (userId: string, offset: number, limit: number) => {

        return await request.get(
            `user/${userId}/message/list?offset=${offset}&limit=${limit}`
        );
    }


    static memoRead = async (userId: string, messageIdx: number) => {
        return await request.get(
            `user/${userId}/message/select/${messageIdx}`
        );
    }

    static memoAlarm = async (userId: string) => {
        return await request.get(
            `user/${userId}/message/alarm`
        );
    }

}