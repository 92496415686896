import imgSlide1 from "../asset/images/slide1.png"
import imgSlide2 from "../asset/images/slide2.png"

import imgSlideM1 from "../asset/images/slider-mobile-1.png"
import imgSlideM2 from "../asset/images/slider-mobile-2.png"
import imgWonM from "../asset/images/mobile-won-sign.png"
import imgInquiry from "../asset/images/inquiry-icon.png"
import imgContact from "../asset/images/contact-icon.png"

import imgBtnDeposit from "../asset/images/deposit-button.png"
import imgBtnWithdraw from "../asset/images/withdraw-button.png"
import imgBtnMove from "../asset/images/money-move-button.png"
import imgBtnDM from "../asset/images/dm-button.png"
import imgLogo2 from "../asset/images/logo-02.png"

import imgOdometer from "../asset/images/odometer.png"
import imgOdometerM from "../asset/images/mobile-odometer-small.png"

import SimpleImageSlider from "react-simple-image-slider";
import { useState, useEffect } from "react"


import { BoardService } from "../service/service"
// import $ from "jquery";
// require("http://code.jquery.com/jquery-latest.js");
// require("../asset/js/jquery-jOdometer.min.js")
// import "../asset/js/jquery-jOdometer.min.js";

import Odometer from "../component/odometer"

const images = [

    { url: imgSlide1 },
    { url: imgSlide2 },
];

const imagesM = [

    { url: imgSlideM1 },
    { url: imgSlideM2 },
];


const sleep = (ms: number) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}




function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
}


const getPos = (p: number) => {
    return (p + 1) * - 95
}

const temp = [getPos(1), getPos(4), getPos(4), getPos(8), getPos(8), getPos(8), getPos(9), getPos(3)]



const Main = ({ setModal, toast }: any) => {

    const [counter, setCounter] = useState(39888441);
    const [pos, setPos] = useState(temp);
    const [rows, setRows] = useState<any[]>([])


    const getNoticeList = async () => {
        const result = await BoardService.noticeList(0, 5);

        setRows(result.data.data[0])
    }

    const openModal = (cmd: string) => {
        if (sessionStorage.getItem("id") == null) {
            toast("로그인이 필요합니다.")

        } else {
            setModal(cmd);
        }
    }





    const getOmovalue = (p: number) => {

        // let temp = Math.floor(counter % Math.pow(10, p + 1) / Math.pow(10, p));
        // console.log(p, temp);

        let po = pos[p];

        //let pos = Math.ceil((Math.pow(10, p) - counter / Math.pow(10, p) )) % 100;
        return `${po}px`
    }


    const getOmovalueM = (p: number) => {

        // let temp = Math.floor(counter % Math.pow(10, p + 1) / Math.pow(10, p));
        // console.log(p, temp);

        let po = pos[p];

        //let pos = Math.ceil((Math.pow(10, p) - counter / Math.pow(10, p) )) % 100;
        return `${po}px`
    }

    useEffect(() => {

        let inc = counter + getRandomInt(10, 50);

        getNoticeList().then()

        // setTimeout(async () => {



        //     // setP(p + 1);
        //     // if(p == 10){
        //     //     await sleep(1000);
        //     //     setP(0);
        //     // }

        //     //setCounter(counter + getRandomInt(10, 50));
        //     //console.log(counter);
        // }, 50);


        // setInterval(async ()=>{
        //     setCounter(counter + 1);
        //     console.log(counter);
        //     await setTimeout((Reserved:any) => {
        //         Reserved();
        //     }, 10000);
        // }, 1000)
    }, [counter])


    useEffect(() => {
        // $('.pc .jackpot-odometer').jOdometer({
        //     increment: 24,
        //     counterStart:39888441,
        //     counterEnd: false,
        //     numbersImage: '/images/sample6/odometer.png?ver=1.01',
        //     spaceNumbers: -3,
        //     formatNumber: true,
        //     widthNumber: 45,
        //     heightNumber: 95
        // });

        // $('.mob .jackpot-odometer').jOdometer({
        //     increment: 24,
        //     counterStart: 39888441,
        //     counterEnd: false,
        //     numbersImage: '/images/sample6/mobile-odometer-small.png',
        //     spaceNumbers: 0,
        //     formatNumber: true,
        //     widthNumber: 17,
        //     heightNumber: 36
        // });
    }, [])


    return (
        <div className="main-page" style={{ minWidth: "500px", fontFamily: "'S-CoreDream-4Regular','MalgunGothic', sans-serif" }}>
            {/* <banner-slider-page> */}
            <div className="banner">
                <div className="pc banner-slider">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <br />
                        <SimpleImageSlider style={{ marginTop: 115, objectFit: "cover" }}
                            width={1920}
                            height={450}
                            images={images}
                            showBullets={false}
                            showNavs={false}
                            autoPlay={true}
                            autoPlayDelay={5}
                        />

                    </div>
                </div>
                <div className="mob banner-slider">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <SimpleImageSlider style={{ marginTop: 0, objectFit: "cover", height: "260px" }}
                            width={550}
                            height={277}
                            images={imagesM}
                            showBullets={false}
                            showNavs={false}
                            autoPlay={true}
                            autoPlayDelay={5}
                        />


                    </div>

                </div>
                <div className="mob wallet-buttons-container" style={{ marginTop: "30px" }}>
                    <button className="btn btn-wallet withdraw" onClick={() => { setModal("Login") }} >로그인</button>
                    <button className="btn btn-wallet deposit ng-scope ngdialog-open btnRegister" data-pop="join" onClick={() => {
                        setModal("Join1");
                    }} >회원가입</button>
                </div>
                <div className="main-container games">
                    <div className="content">
                        <div className="mob container-jackpot click-disable">
                            <div className="currency-sign">
                                <img src={imgWonM} width="50" alt="" />
                            </div>
                            <div className="odometer-container clearfix ">

                                <div className="jackpot-odometer">
                                    <img style={{ position: "absolute", right: "0px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <img style={{ position: "absolute", right: "17px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <img style={{ position: "absolute", right: "34px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <div style={{ position: "absolute", width: "10px", height: "36px", right: "51px", background: "url(../asset/images/mobile-odometer-small.png)" }} className="jodometer_dot"></div>
                                    <img style={{ position: "absolute", right: "61px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <img style={{ position: "absolute", right: "78px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <img style={{ position: "absolute", right: "95px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <div style={{ position: "absolute", width: "10px", height: "36px", right: "51px", background: "url(../asset/images/mobile-odometer-small.png)" }} className="jodometer_dot"></div>
                                    <img style={{ position: "absolute", right: "122px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    <img style={{ position: "absolute", right: "139px", top: "-360px" }} className="jodometer_integer_0" src={imgOdometerM} alt="Integer 1" />
                                    {/* <img style="position: absolute; right: 0px; top: -360px;" className="jodometer_integer_0" src="/images/sample6/mobile-odometer-small.png" alt="Integer 1" />
                                    <img style="position: absolute; right: 17px; top: -252px;" className="jodometer_integer_1" src="/images/sample6/mobile-odometer-small.png" alt="Integer 2" />
                                    <img style="position: absolute; right: 34px; top: -72px;" className="jodometer_integer_2" src="/images/sample6/mobile-odometer-small.png" alt="Integer 3" /> */}
                                    {/* <div style="position:absolute; width:10px; height:36px; background:url(/images/sample6/mobile-odometer-small.png) no-repeat center bottom; right:51px;" className="jodometer_dot"></div>
                                    <img style="position: absolute; right: 61px; top: -36px;" className="jodometer_integer_3" src="/images/sample6/mobile-odometer-small.png" alt="Integer 4" />
                                    <img style="position: absolute; right: 78px; top: -360px;" className="jodometer_integer_4" src="/images/sample6/mobile-odometer-small.png" alt="Integer 5" />
                                    <img style="position:absolute; right:95px; top:-324px;" className="jodometer_integer_5" src="/images/sample6/mobile-odometer-small.png" alt="Integer 6" />
                                    <div style="position:absolute; width:10px; height:36px; background:url(/images/sample6/mobile-odometer-small.png) no-repeat center bottom; right:112px;" className="jodometer_dot"></div>
                                    <img style="position:absolute; right:122px; top:-360px;" className="jodometer_integer_6" src="/images/sample6/mobile-odometer-small.png" alt="Integer 7" />
                                    <img style="position:absolute; right:139px; top:-144px;" className="jodometer_integer_7" src="/images/sample6/mobile-odometer-small.png" alt="Integer 8" /> */}

                                </div>
                            </div>
                        </div>
                        <div data-pop="game" data-item="casino" className="ngdialog-open game-buttons live no-mini" onClick={() => { openModal("Live") }} >
                            <span className="ng-binding">라이브 카지노</span>
                            <span className="ng-binding pc">세계 최고의 카지노 게임들을 만나보세요.</span>
                        </div>

                        <div data-pop="game" data-item="slot" className="ngdialog-open game-buttons slot no-mini" onClick={() => { openModal("Slot") }} >
                            <span className="ng-binding">슬롯게임</span>
                            <span className="ng-binding pc">최고의 슬롯게임에서 잭팟을 도전하세요.</span>
                        </div>
                        <div id="vue_container">
                            <div className="pc transaction-page">
                                <div className="transaction-container">
                                    <table className="transaction-table tb_main_notice" style={{ height: "243px" }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    <div className="table-heading">
                                                        <span>공지사항</span>
                                                        <span data-pop="header" data-item="item-notice" className="pull-right ngdialog-open" onClick={() => { openModal("Notice") }} >전체 보기</span>
                                                    </div>
                                                </th>
                                            </tr></thead>
                                        <tbody>
                                            {rows.map((el, index) => {
                                                return (
                                                    <tr key={index} role="button">
                                                        <td className="width30" style={{ width: "134px" }}>
                                                            <span className="ng-binding">{el.writeAt.substring(0, 19).replace("T", " ")}</span></td>
                                                        <td className="ngdialog-open width70 click-disable text-white text-left">
                                                            <span className="ng-binding" onClick={() => { openModal("Notice") }}  >{el.title}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>

                                    <div className="transaction-table" style={{ width: "830px", height: "243px" }}>
                                        <div className="pc game-buttons click-disable others" style={{ width: "100%", cursor: "default" }}>
                                            <div className="odometer-container clearfix ">

                                                <Odometer counterStart="39888441" widthNumber="45" heightNumber="95" spaceNumbers="-3" increment="24"></Odometer>

                                                {/* $('.pc .jackpot-odometer').jOdometer({
            increment: 24,
            counterStart:39888441,
            counterEnd: false,
            numbersImage: '/images/sample6/odometer.png?ver=1.01',
            spaceNumbers: -3,
            formatNumber: true,
            widthNumber: 45,
            heightNumber: 95
        });

        $('.mob .jackpot-odometer').jOdometer({
            increment: 24,
            counterStart: 39888441,
            counterEnd: false,
            numbersImage: '/images/sample6/mobile-odometer-small.png',
            spaceNumbers: 0,
            formatNumber: true,
            widthNumber: 17,
            heightNumber: 36
        }); */}


                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="customer-page">
                            <div className="customer-content">
                                <div className="customer-buttons click-disable" style={{ textAlign: "left" }}>
                                    <img src={imgInquiry} alt="" />
                                    <div className="text-con text-left" style={{ minWidth: "200px" }}>
                                        <p>365 게임문의</p>
                                        <p className="goldTxt ng-binding" ng-bind="techContactNumber">
                                            텔레그램
                                            <span>@bmc1004</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="customer-buttons click-disable" style={{ textAlign: "left" }}>
                                    <img src={imgContact} alt="" />
                                    <div className="text-con text-left">
                                        <p>365 결제문의</p>
                                        <p className="goldTxt ng-binding" ng-bind="bankContactNumber">
                                            카톡
                                            <span></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>



                        <div className="pc quick-links-page">
                            <div className="help-container">
                                <p>마이페이지</p>
                                <div data-pop="mypage" data-item="item-cash-in" className="ngdialog-open quick-links-area" onClick={() => { openModal("Deposit") }}>
                                    <img src={imgBtnDeposit} />
                                    <p className="qtitle">입금신청</p>
                                </div>
                                <div data-pop="mypage" data-item="item-cash-out" className="ngdialog-open quick-links-area" onClick={() => { openModal("Withdraw") }} >
                                    <img src={imgBtnWithdraw} />
                                    <p className="qtitle">출금 신청</p>
                                </div>
                                <div data-pop="mypage" data-item="item-mypage" className="ngdialog-open quick-links-area" onClick={() => { openModal("MyPage") }} >
                                    <img src={imgBtnMove} />
                                    <p className="qtitle">마이페이지</p>
                                </div>
                                <div data-pop="header" data-item="item-letter" className="ngdialog-open dm-container quick-links-area item-img-letter" onClick={() => { openModal("Memo") }}  >
                                    <img src={imgBtnDM} className="dm-image" />
                                    <p className="qtitle">쪽지함</p>
                                    <span ng-hide="!loggedIn" className="guest-dm badge ng-binding ng-hide" ng-bind="UnreadDM"></span>

                                </div>
                            </div>
                            <div className="credits-container">
                                <img src={imgLogo2} />
                                <p>Copyright 2022 All Rights Reserved.</p>
                            </div>
                            <div className="quick-links-container">
                                <p>Quick Service</p>
                                <ul className="list-inline live" id="live_list">
                                    <li data-code="asian.live" className="ng-binding ">아시아게이밍</li>
                                    <li data-code="uigcasino.live" className="ng-binding ">UIG카지노</li>
                                    <li data-code="evolution.live" className="ng-binding ">에볼루션</li>
                                    <li data-code="dgaming.live" className="ng-binding ">드림게이밍</li>
                                    <li data-code="pragmatic.live" className="ng-binding ">프라그마틱</li><li data-code="sexycasino.live" className="ng-binding ">섹시카지노</li><li data-code="vivo.live" className="ng-binding ">비보게이밍</li><li data-code="wmcasino.live" className="ng-binding ">WM카지노</li><li data-code="betgamestv.live" className="ng-binding ">벳게임즈TV</li><li data-code="luckystreak.live" className="ng-binding ">럭키스트릭</li><li data-code="tvbet.live" className="ng-binding ">TV벳</li><li data-code="xpro.live" className="ng-binding ">XPro게이밍</li><li data-code="bota.live" className="ng-binding ">보타카지노</li></ul>
                                <ul className="list-inline live"><li data-pop="game" data-item="slot" className="ngdialog-open ng-binding">슬롯게임</li></ul>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                    </div>
                </div>
            </div>

        </div >


    )
}

export default Main;

