import imgFooter from "../asset/images/footer.png";
import imgNotice from "../asset/images/notice-icon.svg";
import imgPromo from "../asset/images/promo-icon.svg";
import imgCustmer from "../asset/images/customer-icon.svg";
import imgDm from "../asset/images/dm-icon.svg";

const Footer = ({setModal, toast}:any) => {

    const openModal = (cmd: string) => {
        if (sessionStorage.getItem("id") == null) {
            toast("로그인이 필요합니다.")

        } else {
            setModal(cmd);
        }
    }

    return (

        <div className="footer-page" style={{minWidth:"550px"}} >
            <div className="main-container">
                <img src={imgFooter} width="100%" />
            </div>

            <div className="mob footer main">

                <div className="mob customercenter-container">
                    <ul className="list-inline list-unstyled">
                        <li className="ngdialog-open" data-pop="header" data-item="item-notice"  onClick={() => { openModal("Notice") }}>
                            <img src={imgNotice} className="filter-color-yellow" alt="" />
                            <p>공지사항</p>
                        </li>
                        <li className="ngdialog-open" data-pop="header" data-item="item-event" onClick={() => { openModal("MyPage") }} >
                            <img src={imgPromo} className="filter-color-yellow" alt="" />
                            <p>마이페이지</p>
                        </li>
                        <li className="ngdialog-open" data-pop="header" data-item="item-qna"  onClick={() => { openModal("Qna") }}>
                            <img src={imgCustmer} className="filter-color-yellow" alt="" />
                                <p>고객센터</p>
                        </li>
                        <li className="ngdialog-open" data-pop="header" data-item="item-letter" onClick={() => { openModal("Memo") }}>
                            <img src={imgDm} className="filter-color-yellow" alt="" />
                                <p>쪽지함 </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Footer
