import imgCasino33 from "../../asset/images/casino_33.png";
import imgCasino33Hover from "../../asset/images/casino_33-hover.png";

import imgCasino1 from "../../asset/images/casino_1.png";
import imgCasino1Hover from "../../asset/images/casino_1-hover.png";

import imgCasino54 from "../../asset/images/casino_54.png";
import imgCasino54Hover from "../../asset/images/casino_54-hover.png";

import imgCasino8 from "../../asset/images/casino_8.png";
import imgCasino8Hover from "../../asset/images/casino_8-hover.png";


import imgCasino12 from "../../asset/images/casino_12.png";
import imgCasino12Hover from "../../asset/images/casino_12-hover.png";

import imgCasino28 from "../../asset/images/casino_28.png";
import imgCasino28Hover from "../../asset/images/casino_28-hover.png";


import imgCasino29 from "../../asset/images/casino_29.png";
import imgCasino29Hover from "../../asset/images/casino_29-hover.png";

import imgCasino46 from "../../asset/images/casino_46.png";
import imgCasino46Hover from "../../asset/images/casino_46-hover.png";


import imgCasino47 from "../../asset/images/casino_47.png";
import imgCasino47Hover from "../../asset/images/casino_47-hover.png";


import imgCasino51 from "../../asset/images/casino_51.png";
import imgCasino51Hover from "../../asset/images/casino_51-hover.png";

import imgCasino53 from "../../asset/images/casino_53.png";
import imgCasino53Hover from "../../asset/images/casino_53-hover.png";

import { GameService } from "../../service/service";


const Live = ({ setModal, gameWindow, setGameWindow, toast }: any) => {

    const sleep = (ms: number) => {
        return new Promise(resolve => {
            setTimeout(resolve, ms)
        })
    }


    // 게임 URL API
    const gameOpen = async (tpIdx: string, gameId: string) => {
        const id = sessionStorage.getItem("id");
        const data = { userId: id + "", tIdx: tpIdx, gameId: gameId };
        
        try {
            setModal("Loading")

            if (!(!gameWindow || gameWindow.closed)) {
                gameWindow.close();
                sleep(3000);

            }


            const result = await GameService.getGameUrl(data);            
            setGameWindow(window.open(result.data.data));
            setModal("")

        } catch (error:any) {
            setModal("")
            

            if(error.response.data.message == "Failed to transfer cash"){
                toast("머니 전송이 실패하였습니다. 열려있는 게임이 있으면 닫아주세요.");
            }else{                
                toast(error);
            }
            
            
            
        }
    };

    return (


        <div id="main-popup1">            
            <div className="ngdialog ngdialog-theme-default ngdialog-gamespopup ng-scope">
                <div className="ngdialog-overlay"></div>
                <div className="ngdialog-content" role="document">
                    <div className="ngdialog-games-page" ng-init="activeCategory">
                        <div className="title ng-binding">라이브 카지노</div>
                        <div className="game-button-container live live_list" id="casinos">
                            <div className="game-buttons play_live" data-pop="" data-title="프라그매틱">

                                <img className="game" src={imgCasino33} alt="pragmatic" />
                                <img className="mouseover" src={imgCasino33Hover} />

                                <span className="tit">프라그매틱</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("12", "101");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="에볼루션">

                                <img className="game" src={imgCasino1} alt="evolution" />
                                <img className="mouseover" src={imgCasino1Hover} />

                                <span className="tit">에볼루션</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("3", "live");
                                }}
                                >게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="마이크로게이밍">

                                <img className="game" src={imgCasino54} alt="MGPCASINO" />
                                <img className="mouseover" src={imgCasino54Hover} />

                                <span className="tit">마이크로게이밍</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("7", "SMG_titaniumLiveGames_MP_Baccarat");
                                }}
                                >게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="드림게이밍">

                                <img className="game" src={imgCasino8} alt="dg" />
                                <img className="mouseover" src={imgCasino8Hover} />

                                <span className="tit">드림게이밍</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("16", "lobby");
                                }}>게임입장</button>

                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="WM 카지노">

                                <img className="game" src={imgCasino12} alt="wm" />
                                <img className="mouseover" src={imgCasino12Hover} />

                                <span className="tit">WM 카지노</span>

                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("6", "casino");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="TG/PD">

                                <img className="game" src={imgCasino28} alt="TGSPEED" />
                                <img className="mouseover" src={imgCasino28Hover} />

                                <span className="tit">TG/PD</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("9", "speed");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="아시아게이밍">

                                <img className="game" src={imgCasino29} alt="asiagaming" />
                                <img className="mouseover" src={imgCasino29Hover} />

                                <span className="tit">아시아게이밍</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("11", "0");
                                }}>게임입장</button>
                            </div>
                            
                            {/* <div className="game-buttons play_live" data-pop="" data-title="베트게임즈">

                                <img className="game" src={imgCasino46} alt="Betgames.tv" />
                                <img className="mouseover" src={imgCasino46Hover} />

                                <span className="tit">베트게임즈</span>
                                <button className="btn btn-yellow btn-play openGameBtn" data-cid="46" data-onoff="on"
                                    data-gameid="BTV-baccarat">게임입장</button>
                            </div>
                             */}
                            {/* <div className="game-buttons play_live" data-pop="" data-title="스카이윈드티비">

                                <img className="game" src={imgCasino47} alt="SkywindLive" />
                                <img className="mouseover" src={imgCasino47Hover} />

                                <span className="tit">스카이윈드티비</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("6", "casino");
                                }}>게임입장</button>
                            </div> */}
                            <div className="game-buttons play_live" data-pop="" data-title="타이산">

                                <img className="game" src={imgCasino51} alt="TAISHAN" />
                                <img className="mouseover" src={imgCasino51Hover} />

                                <span className="tit">타이산</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("15", "taishan");
                                }}>게임입장</button>
                            </div>
                            <div className="game-buttons play_live" data-pop="" data-title="두윈">

                                <img className="game" src={imgCasino53} alt="dowin" />
                                <img className="mouseover" src={imgCasino53Hover} />

                                <span className="tit">두윈</span>
                                <button className="btn btn-yellow btn-play openGameBtn" onClick={() => {
                                    gameOpen("22", "dowin");
                                }}>게임입장</button>
                            </div>

                        </div>
                    </div>
                    <div className="ngdialog-close" onClick={()=>{setModal("")}}></div>
                </div>
            </div>
        </div>
    )
}


export default Live;