import imgLogo1 from "../asset/images/logo1.png";
import imgLogo2 from "../asset/images/logo-02.png";
import Info from "../component/info";

const Header = ({ setModal, toast }: any) => {
    const openModal = (cmd:string) => {
        if(sessionStorage.getItem("id") == null){
            toast("로그인이 필요합니다.")
            
        }else{
            setModal(cmd);        
        }
    }

    return (

        <div className="navigation-page">            
            <div className="header-top"  style={{maxWidth:"1921px", minWidth:"550px"}} >
                <div className="logo-container">

                    <img className="pc" src={imgLogo1} />
                    <img className="mob" src={imgLogo2} width="130" />

                </div>
                <div className="pc nav-container">
                    <div className="main-container">
                        <Info setModal={setModal}></Info>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="pc header-bottom" style={{maxWidth:"1921px"}}>
                <div className="main-container">
                    <div className="links">
                        <ul className="list-inline click-disable">

                            <li className="ngdialog-open" data-pop="game" data-item="casino" onClick={()=>{openModal("Live")}}>

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>라이브카지노</p>
                                <p className="ng-binding">LIVE CASINO</p>
                            </li>

                            <li className="ngdialog-open" data-pop="game" data-item="slot" onClick={()=>{openModal("Slot")}}>

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>슬롯게임</p>
                                <p className="ng-binding">SLOT GAME</p>
                            </li>

                            <li className="ngdialog-open" data-pop="mypage" data-item="item-mypage" onClick={()=>{openModal("MyPage")}}>

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>마이페이지</p>
                                <p className="ng-binding">MY PAGE</p>
                            </li>
                        </ul>
                    </div>
                    <div className="links">
                        <ul className="list-inline click-disable">

                            <li className="ngdialog-open" data-pop="header" data-item="item-notice"  onClick={()=>{openModal("Notice")}} >

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>공지사항</p>
                                <p className="ng-binding">NOTICE</p>
                            </li>

                            <li className="ngdialog-open" data-pop="mypage" data-item="item-cash-in" onClick={()=>{openModal("Deposit")}}>
                                <p className="ng-binding" style={{ marginBottom: "0px" }}>입금 신청</p>
                                <p className="ng-binding">DEPOSIT</p>
                            </li>

                            <li className="ngdialog-open" data-pop="mypage" data-item="item-cash-out" onClick={()=>{openModal("Withdraw")}} >

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>출금 신청</p>
                                <p className="ng-binding">WITHDRAW</p>
                            </li>

                            <li className="ngdialog-open notice letter-qna-panel" data-pop="header" data-item="item-notice" onClick={()=>{openModal("Qna")}} >

                                <p className="ng-binding" style={{ marginBottom: "0px" }}>고객센터</p>
                                <p className="ng-binding">CUSTOMER</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    )

}

export default Header

