import ReactPaginate from "react-paginate";
import Modal from 'react-modal';
import { useState, useEffect } from "react";
import { InquiryService } from "../../../service/service";

const Qna = ({ toast }: any) => {

    const [totalCount, setTotalCount] = useState(0);
    const [cmd, setCmd] = useState("view");
    const [rows, setRows] = useState<any[]>([])
    const [showArticle, setShowArticle] = useState(false);

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [answerContent, setAnswerContent] = useState("")
    
    const [currentPage, setCurrentPage] = useState(1)

    const newInquiry = async () => {
        setCmd("new");
        setShowArticle(true);
    }

    const inquiry = async () => {

        if (title.trim().length == 0) {
            toast("제목을 입력하세요")
        } else if (content.trim().length == 0) {
            toast("문의 내용을 입력하세요")
        } else {
            const result = await InquiryService.inquiry(title, content)
            toast("문의가 접수 되었습니다.");
            setShowArticle(false);
            getList().then();
        }

    }

    const select = async (inquiryIdx: number) => {
        const result = await InquiryService.inquiryView(inquiryIdx);   
        
        setTitle(result.data.data.title)
        setContent(result.data.data.content.replace("\n", "<br />"))        
        setAnswerContent(result.data.data.answerContent.replace("\n", "<br />"))
        setCmd("view");
        setShowArticle(true);

    }


    const getList = async () => {

        const offset = (currentPage-1) * 10;
        const result = await InquiryService.inquiryList(offset, 10);

        setTotalCount(result.data.data[1][0].totalCount);
        setRows(result.data.data[0])
    }

    const changePage = (event: any) => {
        setCurrentPage(event.selected);

    };



    useEffect(() => {

        getList().then();

    }, [currentPage])




    const view = () => {
        return (
            <div style={{ background: "white", boxShadow: "0 15px 20px 0 rgba(0,0,0,.25)", borderRadius: "2px", minWidth: "150px", margin: "5%", minHeight: "100px", maxWidth: "500px", padding: "24px 24px 0 24px" }}>
                <div
                    style={{ color: "#000", fontWeight: "700", background: "#fafafa", borderBottom: "#eee 1px solid", borderRadius: "2px 2px 0 0" }}
                    className="flex justify-center h-[20%] items-center text-5xl bg-lightGreen rounded-t-[10px]">
                    {title}
                </div>
                <div style={{
                    color: "#000", minHeight: "56px", padding: "16px 24px 16px 16px", fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                    fontSize: "14px", lineHeight: "1.4285em",
                    borderBottom: "#eee 1px solid"
                }}

                    className="p-[5%]" dangerouslySetInnerHTML={{ __html: content }}   ></div>
<div style={{
                    color: "#000", minHeight: "56px", padding: "16px 24px 16px 16px", fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                    fontSize: "14px", lineHeight: "1.4285em"
                }}

                    className="p-[5%]" dangerouslySetInnerHTML={{ __html: answerContent }}  ></div>                    
                <div
                    style={{
                        background: "#fbfbfb", borderTop: "#eee 1px solid", borderRadius: "0 0 2px 2px",
                        padding: "4px", marginLeft: "-24px", marginRight: "-24px", minHeight: "43px", textAlign: "right"


                    }}
                >
                    <button style={{ color: "#3593d2", margin: "4px", backgroundColor: "transparent", border: "0", fontSize: "14px", fontWeight: "700", textTransform: "uppercase" }} onClick={() => { setShowArticle(false) }}>확인</button>
                </div>
            </div>
        )
    }


    return (
        <div className="mp-tab qna">
            <Modal
                style={{
                    overlay: {
                        //position: "fixed",
                        backgroundColor: "transparent",
                        outline: "none",
                        zIndex: 2000,
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%", height: "100%"
                    },
                    content: {
                        position: "absolute",
                        backgroundColor: "transparent",
                        overflow: "auto",
                        border: "none",
                        outline: "none",
                        width: "400px",
                        height: "800px",
                        alignSelf: "center",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        // zIndex: 99999,
                        // position: "absolute",
                        // // top: "234.5px",
                        // width: "62.5%", height: "780px"

                    },


                }}
                isOpen={showArticle}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}


                className="w-[50%] h-[58vh] mt-[10%] m-auto bg-bgColor text-lg rounded-[10px] drop-shadow-lg"
            >
                {cmd == "view" ? view() :

                    <div style={{ background: "white", boxShadow: "0 15px 20px 0 rgba(0,0,0,.25)", borderRadius: "2px", minWidth: "150px", margin: "5%", minHeight: "100px", maxWidth: "500px", padding: "24px 24px 0 24px" }}>
                        <div
                            style={{ color: "#000", fontWeight: "700", background: "#fafafa", borderBottom: "#eee 1px solid", borderRadius: "2px 2px 0 0" }}
                            className="flex justify-center h-[20%] items-center text-5xl bg-lightGreen rounded-t-[10px]">
                            <span style={{ marginRight: "20px" }}>제목</span>
                            <input type="text" style={{ width: "240px" }} value={title} onChange={(e) => { setTitle(e.target.value) }}></input>
                        </div>
                        <div style={{
                            color: "#000", minHeight: "56px", padding: "16px 24px 16px 16px", fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                            fontSize: "14px", lineHeight: "1.4285em"
                        }}

                            className="p-[5%]">
                            <textarea style={{ width: "100%", height: "300px" }} value={content} onChange={(e) => { setContent(e.target.value) }}></textarea>

                        </div>
                        <div
                            style={{
                                background: "#fbfbfb", borderTop: "#eee 1px solid", borderRadius: "0 0 2px 2px",
                                padding: "4px", marginLeft: "-24px", marginRight: "-24px", minHeight: "43px", textAlign: "center"
                            }}
                        >
                            <button style={{
                                color: "#3593d2", margin: "4px"
                                , backgroundColor: "transparent", border: "1px solid", borderRadius: "0.5em"
                                , fontSize: "14px", fontWeight: "700"
                                , textTransform: "uppercase"
                            }} onClick={() => { inquiry().then() }}>
                                문의하기</button>

                            <button style={{
                                color: "grey", margin: "4px"
                                , backgroundColor: "transparent", border: "1px solid", borderRadius: "0.5em"
                                , fontSize: "14px", fontWeight: "700"
                                , textTransform: "uppercase"
                            }} onClick={() => { setShowArticle(false) }}>
                                취소하기</button>
                        </div>
                    </div>
                }

            </Modal>
            <div className="ui form" style={{ textAlign: "right", marginRight: "20px", marginBottom: "5px", fontSize: "14px" }}>
                <button uk-toggle="target:#qnaWriteModal" className="ui tiny blue right floated button" aria-expanded="false" onClick={() => { newInquiry() }}>
                    <i className="pencil alternate icon"></i> 1:1 문의하기
                </button>

            </div>
            <table className="bs-table" style={{ width: "95%" }}>
                <thead>
                    <tr><th>문의일시</th> <th>제목</th> <th>답변일시</th></tr>
                </thead>
                <tbody>
                    {
                        rows.map((el, key) => {
                            return (
                                <tr>
                                    <td>{el.createAt.substring(0, 19).replace("T", " ")}</td>
                                    <td onClick={async () => { await select(el.inquiryIdx) }}>{el.title}</td>
                                    <td>
                                        {el.answerCreateAt == null ? "" : el.answerCreateAt.substring(0, 19).replace("T", " ")}
                                        &nbsp;&nbsp;{el.isAnswer!=null && el.isAnswer=="1"?<span style={{background:"green", padding:"5px"}}>답변완료</span>:<span style={{background:"red", padding:"5px"}}>문의접수</span> }
                                    </td>

                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="pagination_box">
                <ReactPaginate
                    pageCount={Math.ceil(totalCount / 10)}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    breakLabel={""}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    onPageChange={changePage}
                    containerClassName={"_pagination"}
                    activeClassName={"active"}
                    previousClassName={"disabled"}
                    nextClassName={"disabled"}
                />

            </div>
        </div>
    )
}

export default Qna;