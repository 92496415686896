import { useState, useEffect } from "react";
import { UserService, AccountService } from "../../service/service";


const Deposit = ({ setModal, toast }: any) => {

    const [amount, setAmount] = useState(0);
    const [nickName, setNickName] = useState("");
    const [id, setId] = useState(sessionStorage.getItem("id"));
    const [owner, setOwner] = useState("");
    const [ownerCheck, setOwnerCheck] = useState("");

    const userInfo = async () => {
        let result = await UserService.getInfo();
        setNickName(result.data.data.nickname)
        setOwnerCheck(result.data.data.bankOwner)
    }

    // 입금신청 API
    const depositApi = async () => {

        const userId = sessionStorage.getItem("id");
        try {

            if (owner != ownerCheck) {
                toast("입금자명이 다릅니다.");
                return;
            }

            const result = await AccountService.deposit(userId, amount.toString());
            toast("입금신청 되었습니다.");
            setModal("");
        } catch (error) {
            toast("오류가 발생했습니다. 관리자에게 문의하세요.")
        }
    };

    useEffect(() => {
        userInfo();


    }, [])

    return (
        <div id="SLB_wide" className="ten wide column" style={{ width: "100%" }}>
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "0px", width: "99%" }} className="ui-draggable ui-resizable">
                <div className="SLB_caption ui-draggable-handle"> </div>
                <div className="SLB_close" onClick={() => { setModal(""); }}></div>
                <div className="SLB_center" >

                    <div role="dialog" id="chargeModal" className="chargeModal popupModal ">

                        <div className="bsm-cont" style={{ width: "99.9%", height: "700px" }}>
                            <div className="bsm-inner" style={{ padding: "0px" }}>
                                <div className="modal-body mypage-tabs">
                                    <div className="mdl-head">
                                        <div className="mdl-title">
                                            <div className="deposit active">
                                                <p className="en">DEPOSIT</p>
                                                <p className="kr">입금신청</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-mdl mp-tab active">

                                        <div className="form-container" style={{ width: "90%" }}>
                                            <div className="form-group">
                                                <div className="labels"><p>회원정보</p>
                                                </div>
                                                <div className="infos"><p>{nickName}({id})</p>
                                                </div></div>
                                            <div className="form-group">
                                                <div className="labels"><p>입금자명</p></div>
                                                <div className="infos">
                                                    <input type="text" name="req_name_replaced" placeholder="가입시 입력한 입금자명과 동일해야 합니다." className="form-control " onChange={(e) => { setOwner(e.target.value) }} />
                                                </div></div>
                                            <div className="form-group">
                                                <div className="labels"><p>입금금액</p></div>
                                                <div className="infos">
                                                    <input id="cash" name="cash" type="text" className="form-control " value={amount.toLocaleString()} />
                                                    <ul className="parsley-errors-list"></ul>
                                                    <div className="btn-grp" style={{ marginTop: "25px" }}>
                                                        <button type="button" onClick={() => { setAmount(amount + 10000) }} >1만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 50000) }}>5만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 100000) }}>10만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 500000) }}>50만</button>
                                                        <button type="button" onClick={() => { setAmount(amount + 1000000) }}>100만</button>
                                                        <button type="button" onClick={() => { setAmount(0) }} >다시입력</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <div className="modal-btn-foot"><div className="btn-cont">
                                            <button type="submit" className="over-style red">
                                                <span className="os-cont" onClick={depositApi} >입금하기</span></button>
                                            <button type="reset" className="over-style">
                                                <span className="os-cont" onClick={() => { setModal("") }}>취소</span></button></div></div>
                                        <div className="deposit-ask">
                                            <button type="button">
                                                <i className="ui icon question"></i> <span>입금계좌문의</span>
                                            </button> <p>* 입금시 꼭 계좌문의를 하세요!</p>
                                        </div>

                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui-resizable-handle ui-resizable-e" style={{ zIndex: 90 }}>
                </div>
                <div className="ui-resizable-handle ui-resizable-s" style={{ zIndex: 90 }}></div>
                <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se"
                    style={{ zIndex: 90 }}></div>
            </div>
        </div>
    )

}


export default Deposit

// <div id="SLB_wide" className="seven wide column" style={{ width: "100%" }} >
// <div id="SLB_content"
//     style={{ zIndex: 99999, position: "absolute", top: "364px", width: "99%" }} className="ui-draggable ui-resizable"></div>
// <div className="SLB_caption ui-draggable-handle"> </div>
// <div className="SLB_close" onClick={() => { setModal(""); }}></div>
// <div className="SLB_center" >
//     <div className="loginModal popupModal " role="dialog" id="loginModal">
//         <div className="bsm-cont" style={{ width: "99.9%", height: "500px" }}>
//             <div className="bsm-inner" style={{ padding: "0px" }}>
//                 <div className="modal-body mypage-tabs">
//                     <div className="mdl-head">
//                         <div className="mdl-title">
//                             <div className="active">
//                                 <p className="en">회원가입</p>
//                                 <p className="kr">추천인 입력</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="tab-mdl mp-tab active">
//                         <div className="form-container">
//                             <div id="formLogin">

//                                 <div className="form-group">
//                                     <div className="infos">
//                                         <input type="text" placeholder="추천인 아이디" name="userid" onChange={(e) => {
//                                             setId(e.target.value);
//                                         }} />
//                                         <ul className="parsley-errors-list" id="parsley-id-3409"></ul>
//                                     </div>
//                                 </div>

//                                 <div className="modal-btn-foot">
//                                     <div className="btn-cont">
//                                         <button className="red over-style login-btn" onClick={()=>{
//                                             idCheck();
//                                         }}>
//                                             <span className="os-cont"><i className="fa fa-lock-open"></i> 다음단계</span>
//                                         </button>
//                                         <button className="join-btn over-style" onClick={()=>{
//                                             setModal("");
//                                         }}>
//                                             <span className="os-cont">
//                                                 <i className="fa fa-file-signature"></i> 취소
//                                             </span>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     </div>
// </div>



// </div>