import { UserService } from "../../service/service";
import { useState } from "react";


const Join2 = ({ setModal, toast }: any) => {

    const [duplicated, setDuplicated] = useState(true);
    const [id, setId] = useState("");
    const [pwd, setPwd] = useState("");
    const [pwd2, setPwd2] = useState("");
    const [nickName, setNickName] = useState("");
    const [tel, setTel] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankOwner, setBankOwner] = useState("");
    const [bankAccount, setBankAccount] = useState("");

    // 중복체크 API
    const idCheck = async () => {
        try {
            const result = await UserService.idCheck(id);
            toast("사용 가능한 ID입니다.")
            setDuplicated(false);            
        } catch (error: any) {            
            if (error.response.data.code === "DUPLICATE_ID") {
                toast("이미 존재하는 ID입니다.");
            }
        }
    };

    // 회원가입 Api
    const signUpApi = async () => {

        try {

            if (id.trim().length < 3) {
                toast("아이디를 입력하세요");
                return;
            }

            if (duplicated) {
                toast("아이디 중복확인이 필요합니다.");
                return;
            }


            if (pwd.trim().length < 3) {
                toast("암호를 입력하세요");
                return;
            }


            if (pwd != pwd2) {
                toast("암호가 일치하지 않습니다.");
                return;
            }

            const pCode = sessionStorage.getItem("pCode") + "";
            const result = await UserService.signup(id, pwd, nickName, tel, pCode, bankName, bankOwner, bankAccount);
            setModal("");
            toast("회원가입 완료 되었습니다.");
        } catch (error: any) {
            if (error.response.data.code == "Wrong Recommender Id") {
                toast("잘못된 code 값입니다. 고객센터에 문의하세요.");
            } else {
                toast("회원가입이 실패하였습니다.");
            }
        }
    };


    return (
        <div id="SLB_wide" className="seven wide column" style={{ width: "100%" }} >

            <div className="SLB_caption ui-draggable-handle"> </div>
            <div className="SLB_close" onClick={() => { setModal(""); }}></div>
            <div className="SLB_center" >
                <div className="loginModal popupModal " role="dialog" id="loginModal">
                    <div className="bsm-cont" style={{ width: "99.9%", height: "800px" }}>
                        <div className="bsm-inner" style={{ padding: "0px" }}>
                            <div className="modal-body mypage-tabs">

                                <div className="tab-mdl active">
                                    <div id="fregisterform">
                                        <div className="form-container">
                                            <div className="form-group w-btn">
                                                <div className="labels">
                                                    <p>회원아이디</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " name="userid" id="userid" type="text" onChange={(e) => { setId(e.target.value) }} />
                                                    <button className="button" type="button" onClick={() => {
                                                        idCheck();
                                                    }} >중복확인</button>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>비밀번호</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " name="passwd" id="passwd" type="password" onChange={(e) => { setPwd(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>비밀번호 확인</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " name="passwd_re" id="passwd_re" type="password" onChange={(e) => { setPwd2(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>닉네임</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }} >
                                                    <input className="form-control " name="nickname" id="nickname" type="text" onChange={(e) => { setNickName(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>휴대폰번호</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " name="phone" id="phone" type="text" onChange={(e) => { setTel(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>출금계좌 은행</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <select name="bank_name" id="bank_name" onChange={(e) => { setBankName(e.target.value) }}>
                                                        <option value="">은행선택</option>
                                                        <option value="국민은행">국민은행</option>
                                                        <option value="농협">농협</option>
                                                        <option value="우리은행">우리은행</option>
                                                        <option value="신한은행">신한은행</option>
                                                        <option value="하나은행">하나은행</option>
                                                        <option value="외환은행">외환은행</option>
                                                        <option value="기업은행">기업은행</option>
                                                        <option value="제일은행">제일은행</option>
                                                        <option value="부산은행">부산은행</option>
                                                        <option value="제주은행">제주은행</option>
                                                        <option value="경남은행">경남은행</option>
                                                        <option value="광주은행">광주은행</option>
                                                        <option value="전북은행">전북은행</option>
                                                        <option value="대구은행">대구은행</option>
                                                        <option value="시티은행">시티은행</option>
                                                        <option value="우체국">우체국</option>
                                                        <option value="상호저축은행">상호저축은행</option>
                                                        <option value="수협">수협</option>
                                                        <option value="산업은행">산업은행</option>
                                                        <option value="신협중앙회">신협중앙회</option>
                                                        <option value="새마을금고">새마을금고</option>
                                                        <option value="메리츠증권">메리츠증권</option>
                                                        <option value="대우증권">대우증권</option>
                                                        <option value="동양종금">동양종금</option>
                                                        <option value="삼성생명">삼성생명</option>
                                                        <option value="미래에셋">미래에셋</option>
                                                        <option value="현대증권">현대증권</option>
                                                        <option value="삼성증권">삼성증권</option>
                                                        <option value="한국투자증권">한국투자증권</option>
                                                        <option value="우리투자증권">우리투자증권</option>
                                                        <option value="하이투자증권">하이투자증권</option>
                                                        <option value="HMC투자증권">HMC투자증권</option>
                                                        <option value="대신증권">대신증권</option>
                                                        <option value="한화증권">한화증권</option>
                                                        <option value="신한금융투자">신한금융투자</option>
                                                        <option value="카카오뱅크">카카오뱅크</option>
                                                        <option value="토스뱅크">토스뱅크</option>
                                                        <option value="K뱅크">K뱅크</option>
                                                        <option value="유안타증권">유안타증권</option>
                                                        <option value="산림조합">산림조합</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>예금주</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " id="bank_owner" name="bank_owner" type="text" onChange={(e) => { setBankOwner(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels">
                                                    <p>계좌번호</p>
                                                </div>
                                                <div className="infos" style={{ paddingLeft: "110px" }}>
                                                    <input className="form-control " id="bank_account" name="bank_account" type="text" pattern="[0-9\-]+" onChange={(e) => { setBankAccount(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-btn-foot">
                                            <div className="btn-cont">
                                                <button type="submit" className="over-style red" onClick={() => {
                                                    signUpApi();
                                                }}>
                                                    <span className="os-cont">가입하기</span>
                                                </button>
                                                <button type="reset" className="over-style" onClick={() => { setModal("") }}>
                                                    <span className="os-cont">취소</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}


export default Join2;