import imgIcon from '../asset/images/plus-casino.com_5_icon.png';
import imgMemo from '../asset/images/ico_memo.png';
import imgMoney from '../asset/images/ico_money.png';
import imgPoint from '../asset/images/ico_point.png';
import { UserService } from '../service/service';
import { MemoService } from '../service/service';

import { useEffect, useState } from 'react';

const Info = ({ setModal }: any) => {

    const [balance, setBalance] = useState(0)
    const [point, setPoint] = useState(0)
    const [messages, setMessages] = useState(0)

    const checkBalance =async () => {
    
        try{
            if (sessionStorage.getItem("id") != undefined) {
                let result = await UserService.checkBalance(sessionStorage.getItem("id"));
                setBalance(result.data.data.balance);
                setPoint(result.data.data.point);            
                

                let memoResult = await MemoService.memoAlarm(sessionStorage.getItem("id") + "");
                setMessages(memoResult.data.data.messages);                
            }   
        }catch(e:any){
            if(e.response.status == "403"){
                sessionStorage.clear();     
                window.location.reload();
            }            
        }
         
    }

    useEffect(() => {    
        checkBalance().then();
        setInterval(async () => {
            await checkBalance();
        }, 10 * 1000)
    }
    , [])

    const loginForm = () => {
        if (sessionStorage.getItem("id") == undefined) {
            return (
                <button type="button" className="btn btn-header btn-signup sbmt-login2" style={{ marginLeft: "330px", height: "30px", width: "126px" }} onClick={()=>{setModal("Login")}}>로그인</button>
            )
        }

        return (
            <div style={{ marginLeft: "10%", width: "900px" }}>
                <span>
                    <img src={imgIcon} className="user_level_icon" />
                    <a className="win_profile__" style={{ color: "#fff" }}>
                        <span className="t_w t_bold" style={{ fontWeight: "bold" }}>{sessionStorage.getItem("id")}</span>
                    </a>
                    <span className="t_w">님</span>
                </span>
                <span style={{ marginRight: "5px" }}>
                    <img src={imgMemo} style={{ verticalAlign: "middle" }} />
                    <a style={{ color: "white" }}  onClick={()=>{setModal("Memo")}} >
                        <span className="t_gray ">쪽지: [</span><span className="t_w t_bold _has_memo_count">{messages.toLocaleString()}</span><span className="t_gray">]</span>
                    </a>
                </span>
                <span style={{ marginRight: "5px" }}>
                    <img src={imgMoney} style={{ verticalAlign: "middle" }} /><span className="t_gray ">보유머니: </span>
                    <span className="t_w t_bold _has_cash" id="now_money">{balance.toLocaleString()}</span><span className="t_gray ">원</span>
                </span>
                <span style={{ marginRight: "5px" }} onClick={()=>{setModal("Cash")}}>
                    <img src={imgPoint} style={{ verticalAlign: "middle" }}  /><span className="t_gray">포인트: </span>

                    <a href="javascript:;" className="win_point__" style={{ color: "#fff" }}>
                        <span className="t_w t_bold _has_point" id="now_point">{point.toLocaleString()}</span><span className="t_gray">P</span>
                    </a>
                </span>
                <button id="btnMypage" type="button" className="btn btn-header btn-login sbmt-login2" style={{ height: "30px", width: "126px" }} onClick={()=>{setModal("MyPage")}} >마이페이지</button>
                <button id="btnLogout" type="button" className="btn btn-header ngdialog-open" style={{ height: "30px", width: "126px" }} 
                onClick={()=>{
                    sessionStorage.clear();     
                    window.location.reload();
                }}
                >로그아웃</button>
            </div>
        )
    }


    return (
        <div className="form" style={{ left: "30.9%" }}>

            {loginForm()}


        </div>

    )
}

export default Info
