import { useState, useEffect } from "react";
import { UserService, AccountService } from "../../service/service";


const Cash = ({ setModal, toast }: any) => {

    const [amount, setAmount] = useState(0);
    const [point, setPoint] = useState(0);
    
    const userInfo = async () => {
        let result = await UserService.checkBalance(sessionStorage.getItem("id"));        
        setPoint(result.data.data.point);      
    }

    // 입금신청 API
    const convertPoint = async () => {

        const userId = sessionStorage.getItem("id");
        try {

            if(point < amount){
                toast("포인트가 부족합니다.");
            }else{
                const result = await AccountService.convertPoint(userId, amount.toString());
                toast("포인트가 전환을 신청하였습니다.");
                setModal("");
            }
    

            
            
        } catch (error) {
            toast("오류가 발생했습니다. 관리자에게 문의하세요.")
            console.log(error);
        }
    };

    useEffect(() => {
        userInfo();


    }, [])

    return (
        <div id="SLB_wide" className="ten wide column" style={{ width: "100%" }}>
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "0px", width: "99%" }} className="ui-draggable ui-resizable">
                <div className="SLB_caption ui-draggable-handle"> </div>
                <div className="SLB_close" onClick={() => { setModal(""); }}></div>
                <div className="SLB_center" >

                    <div role="dialog" id="chargeModal" className="chargeModal popupModal ">

                        <div className="bsm-cont" style={{ width: "99.9%", height: "550px" }}>
                            <div className="bsm-inner" style={{ padding: "0px" }}>
                                <div className="modal-body mypage-tabs">
                                    <div className="mdl-head">
                                        <div className="mdl-title">
                                            <div className="deposit active">
                                                <p className="en">POINT TO CASH</p>
                                                <p className="kr">포인트-&gt;캐시 전환신청</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-mdl mp-tab active">

                                        <div className="form-container" style={{ width: "90%" }}>
                                            <div className="form-group">
                                                <div className="labels"><p>보유포인트</p></div>
                                                <div className="infos"><p>{point.toLocaleString()} POINT</p></div>
                                            </div>
                                            <div className="form-group">
                                                <div className="labels"><p>전환금액</p></div>
                                                <div className="infos">
                                                    <div className="infos">
                                                        <input id="point" name="point" value={amount.toLocaleString()} placeholder="전환요청하실 금액을 만원단위로 입력해주세요" className="form-control " />
                                                        <ul className="parsley-errors-list"></ul>
                                                        <div className="btn-grp" style={{ marginTop: "25px" }}>
                                                            <button type="button" onClick={() => { setAmount(amount + 10000) }} >1만</button>
                                                            <button type="button" onClick={() => { setAmount(amount + 50000) }}>5만</button>
                                                            <button type="button" onClick={() => { setAmount(amount + 100000) }}>10만</button>
                                                            <button type="button" onClick={() => { setAmount(amount + 500000) }}>50만</button>
                                                            <button type="button" onClick={() => { setAmount(amount + 1000000) }}>100만</button>
                                                            <button type="button" onClick={() => { setAmount(0) }} >다시입력</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-btn-foot">


                                        <div className="modal-btn-foot">
                                            <div className="btn-cont">
                                                <button type="submit" className="over-style red" onClick={()=>{convertPoint().then()}}>
                                                    <span className="os-cont">전환하기</span></button>
                                                <button type="reset" className="over-style" onClick={() => { setModal("") }}>
                                                    <span className="os-cont">취소</span></button></div></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ui-resizable-handle ui-resizable-e" style={{ zIndex: 90 }}>
            </div>
            <div className="ui-resizable-handle ui-resizable-s" style={{ zIndex: 90 }}></div>
            <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se"
                style={{ zIndex: 90 }}></div>
        </div>

    )

}


export default Cash
