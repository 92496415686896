import axios, { Axios, AxiosInstance } from "axios";





export default class Request{

  public customAxios : AxiosInstance;

  constructor(baseURL : string){
    this.customAxios = axios.create({
      baseURL: `${baseURL}/api/`,
      headers: {
        accesstoken: sessionStorage.getItem("accessToken"),
        checksum: sessionStorage.getItem("checkSum"),
      },
    });

    this.customAxios.interceptors.request.use(
      (config) => {
        const accessToken = sessionStorage.getItem("accessToken");
        config.headers["Content-Type"] = "application/json";
        config.headers["Authorization"] = accessToken;
    
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );

    this.
    customAxios.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {    
        // rct 로 act 교체
        if (err.response?.status === 403) {
          //   sessionStorage.clear();
          //   alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
          //   window.location.reload();
          if (err.code === "TOKEN_EXPIRE") {
            console.log("act reissue");
            const accessToken = sessionStorage.getItem("accessToken");
            const refreshToken = sessionStorage.getItem("refreshToken");
            const id = sessionStorage.getItem("id");
            const result = await axios.post(
              `${baseURL}/api/user/tokens`,          
              { accessToken, refreshToken, id }
            );
            if (result.status === 200) {
              sessionStorage.setItem("accessToken", result.data);
            } else if (result.status === 403) {
              alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
              sessionStorage.clear();
              window.location.reload();
            }
          }
        }
        return Promise.reject(err);
      }
    );
  }

  
  public get = async (endPoint:string) => {
    return await this.customAxios.get(endPoint);
  }

  public post = async (endPoint:string, data:any) => {
    return await this.customAxios.post(endPoint, data);
  }
  public patch = async (endPoint:string, data:any) => {
    return await this.customAxios.patch(endPoint, data);
  }
}







