import React from 'react';
import Main from './page/main';
import "./asset/css/font-awesome.css"
import "./asset/css/bootstrap.min.css"
import "./asset/css/navigation.css"
import "./asset/css/preloader.css"
import "./asset/css/darkmode.css"
import "./asset/css/devel.css"
import "./asset/css/mobile.css"
import "./asset/css/slick-theme.css"
import "./asset/css/slick.css"
import "./asset/css/pagination.css"

import "./asset/css/structure.css"
import "./asset/css/sweetalert2.css"
import "./asset/css/quick-links.css"
import "./asset/css/layout.css"
import "./asset/css/css.css"
import "./asset/css/transaction.css"

import Header from './page/header';
import Footer from './page/footer';
import { useState } from 'react';
import ModalBase from './page/modal/modalBase';
import toast, { Toaster } from 'react-hot-toast';

function App() {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal, setModal] = useState("");

    return (
        <div className="App">

            {/* <div className="SLB_caption ui-draggable-handle"> </div>
                        <div className="SLB_close"></div> */}

            {/* <iframe id="SLB_iframe" src="/mypage?tab=" width="99%" height="93%" className="SLB_center" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" allowtransparency="false'/">
                        </iframe> */}
            {/* <div className="ui-resizable-handle ui-resizable-e" style={{zIndex: 90}}></div>
                        <div className="ui-resizable-handle ui-resizable-s" style={{zIndex: 90}}></div>
                        <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style={{zIndex: 90}}>
                        </div> */}
            {/* </div> */}

            <div className="bg-main">
                <div className="ui centered doubling stackable grid">
                    <Toaster></Toaster>
                    <ModalBase setModal={setModal} modal={modal} toast={toast}></ModalBase>

                    <div className="wrapper">
                        <Header setModal={setModal} toast={toast}></Header>
                        <Main setModal={setModal} toast={toast}></Main>
                        <Footer setModal={setModal} toast={toast}></Footer>

                    </div>


                </div>
            </div>


        </div>
    );
}

export default App;
