
import { useState, useEffect } from "react";
import { UserService } from "../../../service/service";

const MyPageDetail = ({ setModal }: any) => {



    const [balance, setBalance] = useState(0);
    const [point, setPoint] = useState(0);
    const [nickName, setNickName] = useState("");
    const [bankOwner, setBankOwner] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [signupAt, setSignupAt] = useState("");
    const [tel, setTel] = useState("");

    const userInfo = async () => {
        let result = await UserService.getInfo();
        setNickName(result.data.data.nickname)
        setBankOwner(result.data.data.bankOwner)
        setBankName(result.data.data.bankName)
        setBankAccount(result.data.data.bankAccount)
        setSignupAt(result.data.data.signupAt)
        setTel(result.data.data.tel)





    }

    const checkBalance = async () => {

        try {
            if (sessionStorage.getItem("id") != undefined) {
                let result = await UserService.checkBalance(sessionStorage.getItem("id"));
                setBalance(result.data.data.balance);
                setPoint(result.data.data.point);
            }
        } catch (e: any) {
            if (e.response.status == "403") {
                sessionStorage.clear();
                window.location.reload();
            }
        }

    }

    useEffect(() => {
        checkBalance().then();
        userInfo().then();
    }, [])



    return (
        <div className="tab-mdl active">
            <div className="mypage-tabs">
                <div className="mp-tab myinfo active">
                    <div className="mypage-nav" style={{ width: "95%" }}>
                        <button type="button" className="account-link active" style={{ width: "100%" }}><i className="icon icon-User"></i> 나의 정보</button>
                    </div>
                    <div className="form-container">
                        <div className="form-group">
                            <div className="labels"><p>아이디(닉네임) :</p>
                            </div>
                            <div className="infos "><p className="player-balance half">{sessionStorage.getItem("id")}({nickName})</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="labels"><p>보유금액 :</p>
                            </div>
                            <div className="infos"><p className="player-balance half ">{balance.toLocaleString()}</p>
                                <button className="ui tiny teal  button" onClick={() => { setModal("Deposit") }}  ><i className="cloud download  icon"></i>충전
                                </button>
                                <button className="ui tiny blue  button" onClick={() => { setModal("Withdraw") }}  ><i className="cloud upload  icon"></i>출금
                                </button>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="labels"><p>포인트 :</p>
                            </div>
                            <div className="infos"><p className="player-balance half ">{point.toLocaleString()}</p>
                                <button className="ui tiny teal  button"  onClick={()=>{setModal("Cash")}}>
                                    <i className="refresh icon"></i>캐시로 전환
                                </button>
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <div className="labels"><p>금고 :</p>
                            </div>
                            <div className="infos"><p className="player-balance half ">0</p> <button className="ui tiny teal  button"><i className="lock icon"></i>금고보관
                            </button>
                            </div>
                        </div> */}
                        <div className="form-group">
                            <div className="labels"><p>등록계좌정보 :</p>
                            </div>
                            <div className="infos"><p> {bankName} / {bankOwner}<br /> 계좌번호 : ******{bankAccount.substring(bankAccount.length > 4 ? bankAccount.length - 4 : 0)}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="labels"><p>연락처 :</p>
                            </div>
                            <div className="infos"><p>{tel}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="labels"><p>가입일시 :</p>
                            </div>
                            <div className="infos"><p>{signupAt.replace("T", " ").substring(0, 19)}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default MyPageDetail
