import "../../asset/css/mypage.css";
import "../../asset/css/css2/font-icon.css"

import MyPageDetail from "./mypage/mypageDetail";
import Memo from "./mypage/memo";
import Betting from "./mypage/betting";
import DepositHistory from "./mypage/depositHistory";
import WithdrawHistory from "./mypage/withdrawHistory";
import Qna from "./mypage/qna";
import NoticeDetail from "./mypage/notice";



import { useState, useEffect} from "react";

const MyPage = ({ setModal, cmd, toast }: any) => {

    const [active, setActive] = useState(cmd)

    const getDetail = () => {
        switch (active) {
            case "myinfo":
                return <MyPageDetail setModal={setModal}></MyPageDetail>;
            case "memo":
                return <Memo></Memo>
            case "betting":
                return <Betting></Betting>
            case "charge":
                return <DepositHistory></DepositHistory>
            case "withdraw":
                return <WithdrawHistory></WithdrawHistory>
            case "qna":
                return <Qna toast={toast}></Qna>
            case "notice":
                return <NoticeDetail></NoticeDetail>
        }
    }


    return (
        <div id="SLB_wide" className="ten wide column" style={{ width: "100%" }} >
            <div id="SLB_content"
                style={{ zIndex: 99999, position: "absolute", top: "0px", width: "99%" }} className="ui-draggable ui-resizable">

                <div className="SLB_caption ui-draggable-handle"> </div>
                <div className="SLB_close" onClick={() => { setModal("") }}></div>

                <div className="SLB_center" >
                    {/* <div id="dashboard" className="bs-modal modal-dialog mypage" > */}
                    <div role="dialog" id="dashboard" className="chargeModal popupModal ">
                        <div className="bsm-cont" style={{ width: "99.9%", height: "750px" }}>
                            <div className="bsm-inner">
                                <div className="nav-mdl three">
                                    <div className="nav-cont">
                                        <div data-tab="myinfo" className={active == "myinfo" ? "nav-btn myinfo active" : "nav-btn myinfo"}>
                                            <button onClick={() => { setActive("myinfo") }}>
                                                <i className="icon icon-Rolodex"></i> <p className="text">나의 정보</p>
                                            </button>
                                        </div>
                                        <div data-tab="memo" className={active == "memo" ? "nav-btn memo active" : "nav-btn memo"}>
                                            <button onClick={() => { setActive("memo") }}>
                                                <i className="icon icon-Message"></i> <p className="text">쪽지</p></button>
                                        </div>
                                        <div data-tab="betting" className={active == "betting" ? "nav-btn betting active" : "nav-btn betting"}>
                                            <button onClick={() => { setActive("betting") }}>
                                                <i className="icon icon-Podium"></i> <p className="text">배팅/윈</p></button>
                                        </div>
                                        <div data-tab="charge" className={active == "charge" ? "nav-btn charge active" : "nav-btn charge"}>
                                            <button onClick={() => { setActive("charge") }}>
                                                <i className="icon icon-Medal"></i> <p className="text">충전내역</p></button>
                                        </div>
                                        <div data-tab="withdraw" className={active == "withdraw" ? "nav-btn withdraw active" : "nav-btn withdraw"}>
                                            <button onClick={() => { setActive("withdraw") }}>
                                                <i className="icon icon-ClosedLock"></i> <p className="text">환전내역</p></button>
                                        </div>
                                        <div data-tab="qna" className={active == "qna" ? "nav-btn qna active" : "nav-btn qna"}>
                                            <button onClick={() => { setActive("qna") }}>
                                                <i className="icon icon-ClosedLock"></i> <p className="text">1:1문의</p></button>
                                        </div>

                                        <div data-tab="notice" className={active == "notice" ? "nav-btn notice active" : "nav-btn notice"}>
                                            <button onClick={() => { setActive("notice") }}>
                                                <i className="icon icon-Star">
                                                </i> <p className="text">공지사항</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">

                                    <div className="mdl-head">
                                        <div className="mdl-title">
                                            <div className="active">
                                                <p className="en">MY PAGE</p> <p className="kr">마이페이지</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "10px 0px 10px 30px" }}>
                                        {getDetail()}
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div id="myInfoModify" uk-modal="" className="uk-modal">
                <div className="uk-modal-dialog "><form name="myInfoForm" id="myInfoForm" className="ui form equal width">
                    <div className="uk-modal-header"><h3 className="uk-modal-title">회원정보수정</h3>
                    </div> <button uk-close="" className="uk-button uk-modal-close-default uk-icon uk-close"><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                    <div className="uk-modal-body"><table className="ui celled  table"><tbody><tr><td> 회원아이디 ( 닉네임 )</td> <td>
                        <div className="ui left labeled ">
                            <div className="ui  basic label">
                                tted
                                <div className="ui detail"> ( 테드 )</div>
                            </div>
                        </div></td></tr> <tr><td> 보유캐시</td> <td>
                            <div className="ui grid">
                                <div className="column">
                                    <div className="ui teal basic label">250
                                        <div className="detail">캐시</div>
                                    </div>
                                </div>
                            </div></td></tr> <tr><td> 계좌정보</td> <td>
                                <div className="ui list"><span className="item">
                                    <div className="ui horizontal basic label">계좌주</div>국*은</span> <span className="item">
                                        <div className="ui horizontal basic label">계좌번호</div>134*******</span> <span className="item">
                                        <div className="ui horizontal basic label">계좌은행</div>국민은행</span>
                                </div></td></tr> <tr><td>이전 비밀번호</td> <td>
                                    <div className="eight wide field "><input type="password" name="passwd" className="ui input" />
                                    </div></td></tr> <tr><td>신규 비밀번호</td> <td>
                                        <div className="eight wide field "><input type="password" name="passwd_new" className="ui input" />
                                        </div></td></tr> <tr><td>신규 비밀번호 확인</td> <td>
                                            <div className="eight wide field "><input type="password" name="passwd_new_confirm" className="ui input" />
                                            </div></td></tr></tbody></table>
                    </div>
                    <div className="uk-modal-footer"><button className="ui primary submit button">정보수정</button> <button className="ui uk-modal-close button">닫기</button>
                    </div></form> <button uk-close="" className="uk-button uk-modal-close-default uk-icon uk-close"><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                </div>
            </div>
            <div id="qnaWriteModal" uk-modal="" className="uk-modal">
                <div className="uk-modal-dialog"><form name="qnaForm" id="qnaForm" className="ui form equal width">
                    <div className="uk-modal-header"><h3 className="uk-modal-title">1:1 문의하기</h3>
                    </div> <button uk-close="" className="uk-button uk-modal-close-default uk-icon uk-close"><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                    <div className="uk-modal-body">
                        <div className="field required"><label>문의제목</label> <input type="text" name="title" placeholder="title" />
                        </div>
                        <div className="field required"><label>문의하실 내용</label> <textarea name="contents" placeholder="문의내용" className="ui-textarea"></textarea>
                        </div>
                    </div>
                    <div className="uk-modal-footer"><button className="ui primary submit button">문의하기</button>
                        <button className="ui uk-modal-close button">취소</button>
                    </div>
                </form>
                </div>
            </div> */}
            </div>
        </div>

    )

}

export default MyPage